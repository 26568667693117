// ExtEditor - редактор "Видимость поля на внешней форме" в Админке
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { default as VExtEditor } from '../components/AdminParts/ExtEditor'
import {
    FORM_TEMPLATE
   ,EXT_EDITOR
} from '../redux/constants'
import {
    close
} from '../redux/actions/extEditor'
import {
    setPropValue
   ,setKeyPropValue
   ,deleteKeyEmptyProps
} from '../redux/actions/utils'


class ExtEditor extends Component {
 
    render() {
        return (
            <VExtEditor
                { ...this.props }
            />
        )
    }
}


const mapStateToProps = ( { extEditor, doc } ) => {
    return {
        ...extEditor
       ,docTemplate: doc.docTemplates[ doc.docId ]
       ,docParts: doc.docParts
    };
}


const mapDispatchToProps = dispatch => ({
    onChange: ( e ) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
       
        dispatch( setPropValue( EXT_EDITOR, name, value ) );
    }
   ,dispatch // передаю dispatch в props
   ,onOk: ( key, srcProps, attributes ) => {
    
        if( !Array.isArray( attributes ) ) return;
        let props = { ...srcProps };

        // если атрибут совпадает с умолчанием, удалить его
        attributes.forEach( i => {
            if( String( props[i.name] ) === String( i.defaultValue ) ) props[i.name] = undefined; // String, потому что бывает строка '10' и число 10, true и "true"
        });
        
        // сохранить атрибуты в шаблон, пустые удалить
        attributes.forEach( i =>
            dispatch( setKeyPropValue( FORM_TEMPLATE, key, i.name, props[i.name] ) )
        );
        dispatch( deleteKeyEmptyProps( FORM_TEMPLATE, key ) );
    }
   ,onCancel: () => dispatch( close() )
})

export default connect( mapStateToProps, mapDispatchToProps )( ExtEditor )
