import React from 'react'
import * as moment from "moment";
import styles from "./HistoryTable.module.css"
import classNames from 'classnames';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import DescriptionRounded from '@material-ui/icons/DescriptionRounded';
import ListAltRounded from '@material-ui/icons/ListAltRounded';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import EditRounded from '@material-ui/icons/EditRounded';
import {
    transformMarkdown
} from "../../redux/actions/text";


// FYI: сейчас не используется, стиль change пустой
// заглянуть на шаг назад в историю,
// если значение поля изменилось, покрасить поле
const format = ( hist, key, item, attrName, value ) => (
    hist[ key + 1 ] === undefined
 || hist[ key + 1 ][ attrName ] === item[ attrName ]
  ? <td                            >{ value }</td>
  : <td className={ styles.change }>{ value }</td>
);


const HistoryTable = ( { present, past, history, props } ) => {
    if( !present ) return(<></>);
    
    // текущее состояние выводится,
    // если начало не отсекается фильтром "Дата по"
    let hist = moment( present.hist_start_date, "YYYY-MM-DD" ) <= moment( history.dateTo, "DD.MM.YYYY" ) ? [ present ] : [];
    hist = Array.isArray( past ) ? [ ...hist, ...past ] : [ ...hist ];
    
    // ограничить число строк фильтром limit
    const limit = Number.parseInt( history.limit, 10 );
    if( limit >= 0 && Number.isInteger( limit ) ) hist = hist.slice( 0, limit );

    return (
        <>
        
        {/*
        <table width="600" className={ styles.table }>
        <thead>
        <tr>
        <th>Текущая версия</th>
        <th>Дата создания шаблона</th>
        <th>Автор шаблона</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td>{ present.object_version_number }</td>
        <td>{ present.creation_date } <span>{ present.creation_time }</span></td>
        <td><Link href={ "https://staff.yandex-team.ru/" + present.created_by_login } target="_blank" rel="noopener noreferrer" color="inherit">{ present.created_by_login }</Link></td>
        </tr>
        </tbody>
        </table>
        */}
        
        {/*
        <div className={ styles.info }>Дата создания шаблона: { present.creation_date } <span>{ present.creation_time }</span></div>
        */}

        <table className={ classNames( styles.table ) }>
        <thead>
        <tr>
        <th>Версия</th>
        <th>Дата</th>
        {/*<th>Дата с</th>
        <th>Дата по</th>*/}
        {/*<th>Шаблон</th>
        <th>Группа</th>
        <th>Код</th>*/}
        <th>Имя файла шаблона</th>
        <th>Файл</th>
        <th>Форма</th>
        <th>Автор</th>
        <th>Комментарий</th>
        <th width="24px"><EditRounded fontSize="default" /></th>
        </tr>
        </thead>
        <tbody>

        { hist.map( ( i, key ) =>
              <tr key={ key }>
              <td>{ i.object_version_number }</td>
              {/*
                  i.hist_start_date === present.creation_date
               && i.hist_start_time === present.creation_time
                ? <td className={ styles.start_date }>{ i.hist_start_date } <span>{ i.hist_start_time }</span></td>
                : <td                                >{ i.hist_start_date } <span>{ i.hist_start_time }</span></td>
              */}
              {/*<td>{ i.hist_start_date } <span>{ i.hist_start_time }</span></td>*/}
              <td>{ i.hist_end_date } <span>{ i.hist_end_time }</span></td>
              {/* format( hist, key, i, 'name', i.name ) }
              { format( hist, key, i, 'group_name', i.group_name ) }
              { format( hist, key, i, 'code', i.code ) */}
              { format( hist, key, i, 'file_name', i.file_name ) }
              { !i.file_id ? <td className={ styles.doc_icon }><KeyboardArrowUp fontSize="default" style={{ marginLeft: "3px" }} /></td> :
              <td className={ styles.doc_icon }>
                   <IconButton
                       color="inherit"
                       size="small"
                       onClick={ () => props.onDownload(
                           i.template_id
                          ,i.file_id
                          ,( i.name + ' по ' + i.hist_end_date + ' ' + i.hist_end_time.replace(/:/g, '-') ).trim()
                          ,"rtf" // TODO i.file_type, может быть rtf / pdf / docx
                          ,i.hist_id ? "history" : "present"
                          ,i.hist_end_date + ' ' + i.hist_end_time
                          ,history.tzOffset
                       ) }
                   >
                       <DescriptionRounded fontSize="default" />
                   </IconButton>
              </td>
              }
              { !i.text_id ? <td className={ styles.doc_icon }><KeyboardArrowUp fontSize="default" style={{ marginLeft: "3px" }} /></td> :
              <td className={ styles.doc_icon }>
                   <IconButton
                       color="inherit"
                       size="small"
                       onClick={ () => props.onDownload(
                           i.template_id
                          ,i.text_id
                          ,( i.name + ' по ' + i.hist_end_date + ' ' + i.hist_end_time.replace(/:/g, '-') ).trim()
                          ,"json"
                          ,i.hist_id ? "history" : "present"
                          ,i.hist_end_date + ' ' + i.hist_end_time
                          ,history.tzOffset
                       ) }
                   >
                       <ListAltRounded fontSize="default" />
                   </IconButton>
              </td>
              }
              { format( hist, key, i, 'updated_by_login',
                   <Link href={ "https://staff.yandex-team.ru/" + i.updated_by_login } target="_blank" rel="noopener noreferrer" color="inherit">{ i.updated_by_login }</Link>
                )
              }
              <td><span>{ transformMarkdown( i.comment ) }</span></td>
              <td className={ styles.doc_icon }>
                   <div className={ styles.xxAboveEdit }>
                   <div className={ styles.xxEdit }>
                   <IconButton
                       color="inherit"
                       size="small"
                       onClick={ () => props.onCommentEdit(
                           i.hist_id
                          ,i.template_id
                          ,i.hist_end_date + ' ' + i.hist_end_time
                          ,history.tzOffset
                          ,i.comment
                       ) }
                   >
                       <EditRounded fontSize="default" />
                   </IconButton>
                   </div>
                   </div>
              </td>
              </tr>
          )
         
        }
        
        </tbody>
        </table>
        
        </>
    )
}

export default HistoryTable;

