import axios from "axios";
import {
// reducer names
    LIST_EDITOR
} from '../constants';
import {
    setDocStatePropMap
   ,setDocStatePropValue
} from './doc';
import {
    getAdminItemList
   ,getOptionsListByItem
   ,getOptionsListRecord
   ,getOptionsRecordProp
   ,copyProps
   ,dateParts
} from './optionsList';
import {
    // actions
    SET_STATE_PROP_VALUE
    // properties
   ,APP_URL
   ,YA_ORGS
   ,YA_BUSINESS_GROUPS
    // reducer names
   ,DOC
   ,SESSION
   ,YANDEX
} from "../constants";
import {
    get_common
   ,makeUnique
   ,post_common
   ,setPropValue
   ,setProps
} from "./utils";
import {
    caption
} from './lang';


export const setYaOrgsValue = ( data ) => (
    {
        type: YANDEX + SET_STATE_PROP_VALUE
       ,payload: {
            data
        }
    }
)

// id полей используются в шаблонах Word, менять нельзя
export const captions = {
    "ru": {
         ya_name: "Название"
        ,ya_full_name: "Название полное"
        ,ya_inn: "ИНН"
        ,ya_address: "Адрес"
        ,ya_address_eng: "Адрес на английском языке"
        ,ya_reg_num: "Регистрационный номер"
        ,ya_system_name: "Системное имя" // для компоненты "Интеграция с Трекером"
        ,ya_org_id: "Org ID" // для компоненты "Завести в Я.Покупку"
        ,ya_eng_name_full: "Английское наименование полное"
        ,ya_eng_name_short: "Английское наименование краткое"
        ,ya_reg_date: "Дата регистрации"
        ,ya_kpp: "КПП по месту регистрации"
        ,ya_kpp_main: "КПП по месту учёта крупнейшего налогоплательщика"
        ,ya_oktmo: "ОКТМО"
        ,ya_okpo: "ОКПО"
        ,ya_it_num: "Номер ИТ-аккредитации"
        ,ya_it_start_date: "Дата ИТ-аккредитации"
        ,ya_bank_name: "Банк"
        ,ya_bic: "БИК"
        ,ya_payment_account: "Расчётный счёт"
        ,ya_corr_account: "Корреспондентский счёт"
        ,ya_general_visa_login: "Ген. дир. логин"
        ,ya_general_visa_fio: "Ген. дир. ФИО"
        ,ya_general_visa_familyio: "Ген. дир. Фамилия И.О."
        ,ya_general_visa_iofamily: "Ген. дир. И.О. Фамилия"
        ,ya_general_visa_gen: "Ген. дир. ФИО в родительном падеже"
        ,ya_general_visa_dat: "Ген. дир. ФИО в дательном падеже"
        ,ya_general_visa_acc: "Ген. дир. ФИО в винительном падеже"
        ,ya_general_visa_inst: "Ген. дир. ФИО в творительном падеже"
        ,ya_general_visa_prepos: "Ген. дир. ФИО в предложном падеже"
        ,ya_general_visa_job: "Ген. дир. должность"
        ,ya_general_visa_job_gen: "Ген. дир. должность в родительном падеже"
        ,ya_general_visa_job_dat: "Ген. дир. должность в дательном падеже"
        ,ya_general_visa_job_acc: "Ген. дир. должность в винительном падеже"
        ,ya_general_visa_job_inst: "Ген. дир. должность в творительном падеже"
        ,ya_general_visa_job_prepos: "Ген. дир. должность в предложном падеже"
// CDOC-255 МЧД start
        ,ya_general_visa_last_name: "Ген. дир. фамилия"
        ,ya_general_visa_first_name: "Ген. дир. имя"
        ,ya_general_visa_middle_names: "Ген. дир. отчество"
        ,ya_general_visa_birth_date: "Ген. дир. дата рождения"
        ,ya_general_visa_inn: "Ген. дир. ИНН"
        ,ya_general_visa_snils: "Ген. дир. СНИЛС"
        ,ya_general_visa_citizenship: "Ген. дир. гражданство"
// CDOC-255 МЧД end
        ,ya_org_country: "Адрес: Страна"
        ,ya_org_region: "Адрес: Регион"
        ,ya_org_area: "Адрес: Район"
        ,ya_org_town: "Адрес: Город"
        ,ya_org_settlement: "Адрес: Населенный пункт"
        ,ya_org_street: "Адрес: Улица"
        ,ya_org_house: "Адрес: Дом"
        ,ya_org_building: "Адрес: Корпус"
        ,ya_org_apartment: "Адрес: Офис"
        ,ya_org_postal_code: "Адрес: Индекс"
        ,ya_org_combination: "Адрес: Улица, дом, корпус, офис"
    }
    ,"en": {
         ya_name: "Name"
        ,ya_full_name: "Full name"
        ,ya_inn: "INN"
        ,ya_address: "Address"
        ,ya_address_eng: "Address in English"
        ,ya_reg_num: "Registration number"
        ,ya_system_name: "System name"
        ,ya_org_id: "Org ID"
        ,ya_eng_name_full: "Full name in English"
        ,ya_eng_name_short: "Short name in English"
        ,ya_reg_date: "Registration date"
        ,ya_kpp: "KPP at the place of registration"
        ,ya_kpp_main: "KPP at the place of the largest taxpayer registration"
        ,ya_oktmo: "OKTMO"
        ,ya_okpo: "OKPO"
        ,ya_it_num: "IT-accreditation number"
        ,ya_it_start_date: "IT-accreditation date"
        ,ya_bank_name: "Bank"
        ,ya_bic: "BIC"
        ,ya_payment_account: "Payment account"
        ,ya_corr_account: "Correspondent account"
        ,ya_general_visa_login: "General manager Login"
        ,ya_general_visa_fio: "General manager Full name"
        ,ya_general_visa_familyio: "General manager Last name F.M."
        ,ya_general_visa_iofamily: "General manager F.M. Last name"
        ,ya_general_visa_gen: "General manager Full name in Genitive"
        ,ya_general_visa_dat: "General manager Full name in Dative"
        ,ya_general_visa_acc: "General manager Full name in Accusative"
        ,ya_general_visa_inst: "General manager Full name in Instrumental"
        ,ya_general_visa_prepos: "General manager Full name in Prepositional"
        ,ya_general_visa_job: "General manager Position"
        ,ya_general_visa_job_gen: "General manager Position in Genitive"
        ,ya_general_visa_job_dat: "General manager Position in Dative"
        ,ya_general_visa_job_acc: "General manager Position in Accusative"
        ,ya_general_visa_job_inst: "General manager Position in Instrumental"
        ,ya_general_visa_job_prepos: "General manager Position in Prepositional"
// CDOC-255 МЧД start
        ,ya_general_visa_last_name: "General manager Last name"
        ,ya_general_visa_first_name: "General manager First name"
        ,ya_general_visa_middle_names: "General manager Middle names"
        ,ya_general_visa_birth_date: "General manager Date of birth"
        ,ya_general_visa_inn: "General manager INN"
        ,ya_general_visa_snils: "General manager Insurance Number"
        ,ya_general_visa_citizenship: "General manager Citizenship"
// CDOC-255 МЧД end
        ,ya_org_country: "Address: country"
        ,ya_org_region: "Address: region"
        ,ya_org_area: "Address: area"
        ,ya_org_town: "Address: town"
        ,ya_org_settlement: "Address: settlement"
        ,ya_org_street: "Address: street"
        ,ya_org_house: "Address: house"
        ,ya_org_building: "Address: building"
        ,ya_org_apartment: "Address: office"
        ,ya_org_postal_code: "Address: postal code"
        ,ya_org_combination: "Address: street, house, building, office"
    }
};

export const yandexFieldsList = Object.entries( captions[ "ru" ] ).map( e => ({ id: e[0], name: e[1] }) );


// список организаций в компоненте Yandex
// по выбранным БГ
export function yandexOrgsByBgList( componentList, globalLists ) {
 
    let list = [];
    let bgIdList = [];
    if( Array.isArray( componentList ) ) {
        bgIdList = globalLists.yaBGs.filter( i => componentList.includes( i.name ) ).map( i => i.id );
        list = globalLists.yaOrgs.filter( i => bgIdList.includes( i.ya_bg_id ) ).map( i => i.ya_name );
    }

    if( list.length > 0 || bgIdList.length > 0
    ) return list;
    
    // если БГ не выбраны, выберем по умолчанию,
    // и заполним списком организаций из дефолтных БГ
    bgIdList = globalLists.yaBGs.filter( i => String( i.isDefault ) === '1' ).map( i => i.id );
    list = globalLists.yaOrgs.filter( i => bgIdList.includes( i.ya_bg_id ) ).map( i => i.ya_name );
    
    return list;
}


// открыть редактор списка на поле в Админке
export function editListYandex( key, item, props ) {
    const translatedList = item.list;
    
    // optionsList - полный список значений, доступных для отбора в список list,
    // может быть задан в свойствах компонента,
    // массив [...] или имя массива в props.doc
    const optionsList = getOptionsListByItem( props.doc, item );

    const list = getAdminItemList( translatedList, optionsList, "ya_name" ); // список из шаблона или из optionsList

    let list_en = Array.isArray( item.list_en ) ? [ ...item.list_en ] : [];
    if( Array.isArray( optionsList ) ) list_en = undefined;
    
    const dispatch = props.dispatch;
    dispatch(
        setProps( LIST_EDITOR, {
            open: true
           ,idx: key // "idx", потому что нельзя называть "key", React скушает из props
           ,list
           ,list_en
           ,optionsList: Array.isArray( optionsList ) ? optionsList.map( i => i.ya_name ) : undefined
           ,bankDetails: true
        } )
    )
}


/*
Чтобы компонент Yandex зависел от выбора в другом поле,
можно настроить видимость нескольких компонентов Yandex,
при этом можно не задавать суффиксы,
теги компонентов будут называться одинаково.

Eсли списки организаций разные,
то можно проверить, что названия нет в списке, и переключить на defaultValue.
*/
export const defaultValue = ( props, state, extra, suffix, list ) => {
    
    const name = "ya_name" + suffix;
    let value = state[ name ];
    
    // если в выпадающем списке нет текущего значения, сбросить значение Select
    if( value !== undefined && value !== '' && value !== null && Array.isArray( list ) && !list.includes( value ) ) value = undefined;
    if( value !== undefined ) return value;

    value = extra.defaultValue;
    
    // если в выпадающем списке нет текущего значения, сбросить значение Select
    if( value !== undefined && value !== '' && value !== null && Array.isArray( list ) && !list.includes( value ) ) value = undefined;

    if( value === undefined ) return '';

    const dispatch = props.dispatch;
    
    // если не сделать, повесится в цикле
    dispatch( setDocStatePropValue( name, value ) );
    
    // загрузить все поля
    setYandexFields( dispatch, suffix, props, 'ya_name', value );
    
    return value;
}

/*
Банковские сведения
заполняются сверху вниз.
Банк и БИК заполняются синхронно,
по банку отбираются счета.

Проверки:
- можно вбить новые значения
- нельзя вбить новые значения, если стоит галка "Запретить ввод новых данных"
- по умолчанию списки пусты, банковские сведения нельзя выбрать на форме, если они не были выбраны и сохранены Админке
  (или можно сделать по-другому,
   доступны все счета, если не были выбраны и сохранены банковские сведения - не задан whiteList,
   пусто, если в банковских сведениях все галки сняты)
- пусто, если в банковских сведениях все галки сняты
- пусто, если нет default value
- работает с суффиксом
- два поля с разными суффиксами не конфликтуют
- два поля с одним тегом и переключением видимости
- выбор счёта переключает банк (если не фильтровать счета после выбора банка, оставить полный список)
- если печатаем в поле ввода (банк, счёт, любое поле),
  нужно изменить другие поля, если комбинация существует,
  или оставить возможность ввести любые данные, если такой комбинации нет

  
Банковские сведения хранятся в таблице
> 1 млн. записей, есть проверка уникальности комбинации,
есть индекс по ИНН:

SELECT * FROM
xxya.cdoc_vendor_bank_det
WHERE inn = '7736207543'


payment_account почти уникальный:

-- 1395951
SELECT count(*) FROM
xxya.cdoc_vendor_bank_det

-- 1380454
SELECT count(distinct payment_account) FROM
xxya.cdoc_vendor_bank_det

-- 0
SELECT * FROM
xxya.cdoc_vendor_bank_det
WHERE payment_account is null

SELECT * FROM (
SELECT *, count(1) over (partition by payment_account) cnt
FROM xxya.cdoc_vendor_bank_det
) t WHERE cnt > 1


Есть один bic = '044030796' у 2-х разных банков, остальные 1 к 1,
bank_name и correspondent_account относятся 1 к 1:

SELECT * FROM (
SELECT bic, count( distinct correspondent_account ) cnt
FROM xxya.cdoc_vendor_bank_det
GROUP BY bic
) t WHERE cnt > 1


cdoc\db_pg\function\pack_cdoc\getYandexBanks.sql
в процедуре названия возвращаемых полей сделал, как на фронте,
добавил ORDER BY
*/

// заполнить выпадающие списки банков и БИК-ов
function setBanksList( dispatch, tail, bankDetails ) {
    
    // makeUnique, записи уникальны, но отдельные атрибуты повторяются,
    // если сделать makeUnique, то остаётся только выбор по тексту, а комбинация, id строки - теряется
    const ya_bank_list = Array.isArray( bankDetails ) ? makeUnique( bankDetails.map( i => i.ya_bank_name ) ) : [];
    const ya_bic_list = Array.isArray( bankDetails ) ? makeUnique( bankDetails.map( i => i.ya_bic ) ) : [];
    
    dispatch( setPropValue( SESSION, "ya_bank_list" + tail, ya_bank_list ) );
    dispatch( setPropValue( SESSION, "ya_bic_list" + tail, ya_bic_list ) );
}

// заполнить выпадающие списки банковских счетов
function setBankAccountsList( dispatch, tail, bankDetails, ya_bank_name ) {
    
    // отфильтровать по выбранному банку
    const filteredDetails = Array.isArray( bankDetails ) && ya_bank_name ?
        bankDetails.filter( i => i.ya_bank_name === ya_bank_name ) :
        undefined;
        
    const ya_payment_account_list = Array.isArray( filteredDetails ) ? makeUnique( filteredDetails.map( i => i.ya_payment_account ) ) : [];
    const ya_corr_account_list = Array.isArray( filteredDetails ) ? makeUnique( filteredDetails.map( i => i.ya_corr_account ) ) : [];
    dispatch( setPropValue( SESSION, "ya_payment_account_list" + tail, ya_payment_account_list ) );
    dispatch( setPropValue( SESSION, "ya_corr_account_list" + tail, ya_corr_account_list ) );
}

// обновить поля банковских сведений
// clearOnError:
// true - очистить поля, если нет такой комбинации значений,
// false - если комбинации не существует - оставить значения полей несогласованными
function setBankDetailsFields( dispatch, tail, bankDetails, selectedObj, clearOnError = false ) {
    
    const ya_bank_name       = selectedObj ? selectedObj.ya_bank_name : '';
    const ya_bic             = selectedObj ? selectedObj.ya_bic : '';
    const ya_payment_account = selectedObj ? selectedObj.ya_payment_account : '';
    const ya_corr_account    = selectedObj ? selectedObj.ya_corr_account : '';
    
    if( selectedObj || clearOnError ) {
        const state = {
            ["ya_bank_name"       + tail]: ya_bank_name
           ,["ya_bic"             + tail]: ya_bic
           ,["ya_payment_account" + tail]: ya_payment_account
           ,["ya_corr_account"    + tail]: ya_corr_account
        };
        
        dispatch( setDocStatePropMap( state ) );
    }
    
    setBankAccountsList( dispatch, tail, bankDetails, ya_bank_name );
}

// банковские сведения для выбранной организации
function setBankDetailsList( dispatch, tail, bankDetailsFull, item, ya_name ) {

    // TODO нелогичные названия и структура
    // listBankDetails -> whiteLists, map вместо массива, с ключом = org_id
    // yaOrgs.item.bankDetails - тоже лучше сделать map?
    const whiteLists = item.listBankDetails;
    const whiteListObj = Array.isArray( whiteLists ) ? whiteLists.find( i => i.item === ya_name ) : undefined;
    
    // в шаблоне может быть список отобранных id банковских сведений
    // TODO нелогичные названия
    // bankNameWhiteList -> whiteList
    // bankNameDefaultValue -> defaultValue
    const whiteList = whiteListObj ? whiteListObj.bankNameWhiteList : undefined;
    const defaultValue = whiteListObj ? whiteListObj.bankNameDefaultValue : undefined;

    // фильтруем банковские сведения по whiteList
    const bankDetails = Array.isArray( whiteList ) && Array.isArray( bankDetailsFull ) ?
        bankDetailsFull.filter( i => whiteList.includes( i.id ) ) :
        // по умолчанию списки пусты, банковские сведения нельзя выбрать на форме,
        // если они не были выбраны и сохранены Админке
        undefined;
        // или можно сделать по-другому,
        // доступны все счета, если не были выбраны и сохранены банковские сведения - не задан whiteList,
        // пусто, если в банковских сведениях все галки сняты
        //bankDetailsFull;
    
    dispatch( setPropValue( SESSION, "ya_bank_details" + tail, bankDetails ) );

    // выбираем сведения по defaultValue
    // или первые в списке, если defaultValue нет
    const selectedObj = Array.isArray( bankDetails ) ?
        defaultValue ? bankDetails.find( i => i.id === defaultValue ) : bankDetails[0] :
        undefined;

    setBanksList( dispatch, tail, bankDetails );
    
    setBankDetailsFields( dispatch, tail, bankDetails, selectedObj, true );
}

// изменение Банка, БИК или счетов в поле ввода или выбор из списка,
// выбрать комбинацию и обновить все поля,
// clearOnError:
// true - очистить поля, если комбинация не существует,
// false - оставить возможность впечатать любые значения
export function onChangeBankDetails( dispatch, tail, bankDetails, name, value, clearOnError = false ) {
    
    // i[ name ] - названия полей из back должны быть, как на фронте
    const selectedObj = Array.isArray( bankDetails ) ?
        bankDetails.find( i => i[ name ] === value ) :
        undefined;
        
    setBankDetailsFields( dispatch, tail, bankDetails, selectedObj, clearOnError );
}


const emptyRecord = { ...captions[ "ru" ] };
for( const key in emptyRecord ) {
    emptyRecord[ key ] = '';
};


export function setYandexFields(
    dispatch
   ,suffix
   ,props
   ,name
   ,value
) {
    const tail = suffix === undefined || suffix === null ? '' : suffix;
 
    // сначала обнуляем все поля
    let state = {};
    copyProps( state, emptyRecord, tail );

    let record = {};

    if( value ) {
     
        const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
        const optionsList = propsDoc.yaOrgs;
        record = getOptionsListRecord( optionsList, name, value );
        
        if( record && !record.isRead ) {
            loadRecord(
                dispatch
               ,{ [name]: value }
               ,() => setYandexFields( dispatch, suffix, props, name, value )
            );
            return;
        }
        
        const ya_bank_details = getOptionsRecordProp( record, "ya_bank_details" );
        const ya_name         = getOptionsRecordProp( record, "ya_name"         );
        setBankDetailsList( dispatch, tail, ya_bank_details, props, ya_name );
    }

    // если очистили название - обнулить поля,
    // иначе - оставляем возможность заполнить руками,
    // не стираем поля, если запись не найдена в базе
    if( name !== "ya_name" )
        if( !record || !record[ name ] ) return;

    copyProps( state, record, tail );

    // do not overwrite bank details,
    /*// assign the keys to the variable _n indicating it will be unused
    const {
        ya_bank_name: _1
       ,ya_bic: _2
       ,ya_payment_account: _3
       ,ya_corr_account: _4
       ,...newState} = state;*/
    delete state[ "ya_bank_name"       + tail ];
    delete state[ "ya_bic"             + tail ];
    delete state[ "ya_payment_account" + tail ];
    delete state[ "ya_corr_account"    + tail ];

    dateParts( state ); // добавить поля _dd, _mm, _yyyy к полям date
    dispatch( setDocStatePropMap( state ) );
}


// запрос всей информации по одной организации
let cancelRequest;
export function loadRecord( dispatch, org, next ) {

    if( cancelRequest ) {
        // не делаем cancel,
        // запросов не много, и нужно поддержать запросы от разных полей Yandex при загрузке формы
        // cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    // для внешних форм добавляем параметры запроса - для защиты ручки
    const extUserId = dispatch( ( dispatch, getState ) => getState().user.extUserId );

    const config = {
        cancelToken: cancelRequest.token
    };
    
    const params = {
        ...org
    };

    post_common(
        APP_URL + '/api/orgs/org/' + extUserId
       ,params
       ,config
       ,dispatch
       ,caption.yandexOrgError + ': '
       ,data => {
            if( data === 'EXPIRED' ) {
                dispatch( setPropValue( DOC, 'expiredUrl', true ) );
            } else {
                if( !Array.isArray( data ) || !data.length ) return;
                dispatch( setYaOrgsValue( data ) );
                next();
            }
        }
    );
    
}


// список организаций, с сокращённым набором атрибутов
export function loadYaOrgs( dispatch ) {

    // для внешних форм добавляем параметры запроса - для защиты ручки
    const extUserId = dispatch( ( dispatch, getState ) => getState().user.extUserId );
    
    get_common(
        APP_URL + '/api/orgs'
       ,dispatch
       ,caption.yandexListError + ': '
       ,data => {
            if( data === 'EXPIRED' ) {
                dispatch( setPropValue( DOC, 'expiredUrl', true ) );
            } else {
                if( !Array.isArray( data ) || !data.length ) return;
                data.sort( (a,b) => a.ya_name.localeCompare( b.ya_name ) );
                dispatch( setPropValue( DOC, YA_ORGS, data ) );
            }
        }
       ,f=>f
       ,null
       ,{
            params: {
                extUserId
            }
        }
    );
}


// список бизнес-групп
export function loadYaBusinessGroups( dispatch ) {

    // для внешних форм добавляем параметры запроса - для защиты ручки
    const extUserId = dispatch( ( dispatch, getState ) => getState().user.extUserId );
    
    get_common(
        APP_URL + '/api/bgs'
       ,dispatch
       ,caption.yandexBgError + ': '
       ,data => {
            if( data === 'EXPIRED' ) {
                dispatch( setPropValue( DOC, 'expiredUrl', true ) );
            } else {
                if( !Array.isArray( data ) || !data.length ) return;
                data.sort( (a,b) => a.name.localeCompare( b.name ) );
                
                const defaultItem = data.find( i => i.id === 0 );
                if( defaultItem ) defaultItem.isDefault = '1'; // Яндекс, Россия (БГ) выбирается по умолчанию
                
                dispatch( setPropValue( DOC, YA_BUSINESS_GROUPS, data ) );
            }
        }
       ,f=>f
       ,null
       ,{
            params: {
                extUserId
            }
        }
    );
}
