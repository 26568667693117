import React from 'react';
import styles from "./Form.module.css"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { ValidatorForm } from 'react-material-ui-form-validator';
import Grow from '@material-ui/core/Grow';
import {
    setPropValue
} from '../../redux/actions/utils'
import {
    EXT_EDITOR
} from '../../redux/constants'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

// редактор атрибутов раздела ext из DocParts.js

const ExtEditor = ( props ) => {

    if( !props.open ) return '';

    const state = props.docTemplate[ props.idx ];
    const docPart = props.docParts[ state.type ];

    const onOk = props.onOk === undefined ? props.onCancel : () => { props.onCancel(); props.onOk( props.idx, props, docPart.ext ); }
    
    const DynForm = !props.docParts || !docPart.ext ? null :
        docPart.ext.map(
            i => {
                return props.docParts[ i.type ].build( // параметры build( props, state, extra, styles )
                    {
                        ...props
                       ,onChange: (e) => props.onChange( e ) // для компонентов с event-ами
                       ,onNameValueChange: ( name, value ) => props.dispatch( setPropValue( EXT_EDITOR, name, value ) ) // для компонентов без event-ов
                    }
                   ,props // state
                   ,i // extra
                   ,styles
                );
            }
        );
        
    return (
        <Dialog
            open={ true }
            TransitionComponent={ Transition }
            onBackdropClick={ props.onCancel }
            onEscapeKeyDown={ props.onCancel }
        >
        
            <ValidatorForm
                id="extEditorForm"
                onSubmit={ onOk }
            >

                <DialogContent>
                
                    <DialogContentText>{ props.label }</DialogContentText>
                    
                    { DynForm ? DynForm.map( (item, key) => <div key={key}>{ item }</div> ) : '' }

                </DialogContent>
                <DialogActions>

                    <Button
                        onClick={ onOk }
                        color="secondary"
                        variant="outlined"
                        size="small"
                        autoFocus
                    > Ок </Button>
                    
                    { props.onOk === undefined ? '' :
                        <Button onClick={ props.onCancel } color="secondary" variant="outlined" size="small" > Отмена </Button>
                    }
                    
                </DialogActions>
            
            </ValidatorForm>
            
        </Dialog>
    );
};

export default ExtEditor;
