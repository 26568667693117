import React from 'react'
import Link from '@material-ui/core/Link';
import {
   // properties
    APP_URL
   // reducer names
   ,DOC
   ,USER
} from '../constants';
import {
    copyValue
   ,post_common
   ,setPropValue
} from '../actions/utils';
import {
    errorSnackbar
   ,successSnackbarLong
} from '../actions/snackbar';
import {
    hrPersonsFieldsList
} from '../actions/hrPersons';
import {
    secureCleanLocalState
   ,storeLastDocState
} from '../actions/localStorage';


const captions = {
    "ru": {
        extLink: "Внешняя ссылка на шаблон скопирована в буфер обмена\n"
               + "( или можно кликнуть сюда, и скопировать ссылку\n"
               + "из адресной строки открывшегося окна ).\n"
               + "Предзаполненные поля переданы на внешнюю форму.\n"
               + "Ссылка работает до исчерпания лимита: cуток = "
       ,extLinkTail: ", запросов = "
       ,extLinkError: "Ошибка при получении ссылки на внешнюю форму "
       ,extServiceDown: "Внешние формы не работают"
       ,link: "Ссылка на форму скопирована в буфер обмена.\n"
            + "Пожалуйста, передайте её обратно вашему контактному лицу."
       ,linkError: "Ошибка при получении ссылки на форму "
       ,serviceDown: "Сервис временно недоступен"
    }
   ,"en": {
        extLink: "External link to the form has been copied to the clipboard\n"
               + "( or click here and copy the link from the address bar of the newly opened window ).\n"
               + "Predefined fields are sent to the external form.\n"
               + "Link will be actual until the limit is reached: days = "
       ,extLinkTail: ", requests = "
       ,extLinkError: "Error getting link to the external form "
       ,extServiceDown: "External forms do not work"
       ,link: "The link to the form has been copied to the clipboard.\n"
            + "Please send it back to your contact person."
       ,linkError: "Error getting link to the form "
       ,serviceDown: "Service is temporarily unavailable"
    }
};


// получить uuid из адресной строки
export function getExtUserIdFromAddressBar( dispatch ) {
    let uuid = null;
    const regexp = /\/(.{8}-.{4}-.{4}-.{4}-.{12})\//;
    const result = regexp.exec( window.location.href );
    if( result && result.length ) uuid = result[ result.length - 1 ];
    dispatch( setPropValue( USER, 'extUserId', uuid ) );
    return uuid;
}


// чистим состояние формы -
// login, данные HR по списку hrPersonsFieldsList в hrPersons.js
export function secureCleanExtState( dispatch ) {

    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const docId = propsDoc.docId;
    const template = propsDoc.docTemplates[ docId ];
    const state = secureCleanLocalState( dispatch ); // очищенная копия state
    //state.login = undefined; // TODO вроде нужно чистить, но как тогда указать ответственного?
    
    template.filter( i => i.type === "HrPersons" ).forEach(
        component => {
            const tail = component.suffix === undefined || component.suffix === null ? '' : component.suffix;
            hrPersonsFieldsList.forEach(
                i => state[ i.id + tail ] = undefined
           );
           state[ "xxcdoc_hr_login" + tail ] = undefined;
        }
    );

    return state;
}


// ручка для внутреннего пользователя
export function getDocExtUrl( dispatch, docId, lang ) {
    if( !docId || docId <= 0 ) return;
    
    storeLastDocState( dispatch );

    // скрыть секретные данные - пароли, логины, поля из справочника HR
    const state = secureCleanExtState( dispatch );

    // передаём данные частично заполненной формы, чтобы пользователь внешней формы мог дозаполнить остальное
    const config = {headers: {'content-type': 'multipart/form-data'}};
    const formData = new FormData();
    formData.append('state', JSON.stringify( state ));

    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    const lnk = ( url, days, health ) =>
        <span>
        <Link href={ url } target="_blank" rel="noreferrer" color="inherit">
            { caption.extLink + days + caption.extLinkTail + health + '.' }
        </Link>
        </span>;
        
    post_common(
        APP_URL + '/api/ext/url/' + docId
       ,formData
       ,config
       ,dispatch
       ,caption.extLinkError
       ,( data ) => {
            if( data === "UNAUTHORIZED" ) {
                errorSnackbar( data );
            } else {
                copyValue( data.url );
                successSnackbarLong( lnk( data.url, data.days, data.health ) );
            }
        }
       ,f => { errorSnackbar( caption.serviceDown ); return "BREAK"; }
    );
    
}


// ручка для внешнего пользователя
export function getDocYndUrl( dispatch, docId, lang ) {
    if( !docId || docId <= 0 ) return;
    
    storeLastDocState( dispatch );

    const props = dispatch( ( dispatch, getState ) => getState() );
    
    // передаём uuid пользователя внешних форм
    const extUserId = props.user.extUserId;

    // передаём все поля от внешнего пользователя,
    // чтобы внутренний пользователь мог дозаполнить остальное
    const state = props.doc.docStates[ docId ];
    
    const config = {headers: {'content-type': 'multipart/form-data'}};
    const formData = new FormData();
    formData.append('extUserId', extUserId);
    formData.append('state', JSON.stringify( state ));

    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    const lnk = ( url ) =>
        <span>
        <Link href={ url } target="_blank" rel="noopener noreferrer" color="inherit">
            { caption.link }
        </Link>
        </span>;
        
    post_common(
        APP_URL + '/api/ext/ynd-url/' + docId
       ,formData
       ,config
       ,dispatch
       ,caption.linkError
       ,( data ) => {
            if( data === "EXPIRED" ) {
                dispatch( setPropValue( DOC, 'expiredUrl', true ) );
            } else {
                copyValue( data );
                successSnackbarLong( lnk( data ) );
            }
        }
       ,f => { errorSnackbar( caption.serviceDown ); return "BREAK"; }
    );
    
}

