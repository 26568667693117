import React from 'react';
import styles from "./Form.module.css"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {ValidatorForm} from 'react-material-ui-form-validator';
import Grow from '@material-ui/core/Grow';
import MultiSelect from "../DocParts/MultiSelect";
import Select from "../DocParts/Select";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const BankListsEditor = ( props ) => {

    if( !props.open ) return '';
    const onOk = props.onOk === undefined ? props.onCancel : () => { props.onCancel(); props.onOk( props ); }

    const bankDefaultValueList = props.bankDetails.filter(i => props.bankNameWhiteList.includes(i.id) );

    return (
        <Dialog
            open={true}
            TransitionComponent={Transition}
            onBackdropClick={props.onCancel}
            onEscapeKeyDown={props.onCancel}
        >

            <ValidatorForm
                id="listEditorForm"
                onSubmit={onOk}
                className={ styles.xxForm }
            >

                <DialogContent>
                    <DialogContentText> Банковские реквизиты {props.item} </DialogContentText>

                    <MultiSelect
                        label="Банк - допустимые значения"
                        name="bankWhiteList"
                        className={styles.xxSelect}
                        value={props.bankNameWhiteList}
                        onChange={ (e) => props.onChangeBankNameWhiteList(e,props.bankDetails)}
                        list={props.bankNameFullList}
                    />
                    <Select
                        label="Банк - значение по умолчанию"
                        name="bankDefaultValue"
                        className={styles.xxSelect}
                        list={bankDefaultValueList}
                        onChange={props.onChangeBankNameDefaultValue}
                        value={props.bankNameDefaultValue}
                    />

                </DialogContent>
                <DialogActions>

                    <Button
                        onClick={onOk}
                        color="secondary"
                        variant="outlined"
                        size="small"
                        autoFocus
                    > Ок </Button>

                    { props.onOk === undefined ? '' :
                         <Button onClick={ props.onCancel } color="secondary" variant="outlined" size="small" > Отмена </Button>
                     }

                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

export default BankListsEditor;
