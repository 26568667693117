import defaultStyles from "./Form.module.css";
//import Barcode from "./Barcode";
import Ext from "./Ext";
import Text from "./Text";
import Input from "./Input";
import Money from "./Money";
import Multiline from "./Multiline";
import Select from "./Select";
import MultiSelect from "./MultiSelect";
import InputWithSelect from "./InputWithSelect";
import { DateInput, onChangeDate } from "./DateInput";
import Checkbox from "./Checkbox";
import Radio from "./Radio";
import Table from "./Table/Table";
import Subtemplate from "./Subtemplate";
import HrPersons from "./HrPersons";
import SaveAndTicket from "./SaveAndTicket";
import HiddenInput from "./HiddenInput";
import StartrekAttachDoc from "./StartrekAttachDoc";
import StartrekIntegration from "./StartrekIntegration";
import Vendor from "./Vendor";
import Yandex from "./Yandex";
import IprocIntegration from "./IprocIntegration";
import Uuid from "./Uuid";
import PreviewIcon from "../Preview/PreviewIcon";
import {
    TAG_PREFIX
   ,STARTREK_QUEUE_DEFAULT
   ,IPROC
   ,IPROC_SUBTYPE_PROP
   ,NORMAL
   ,GRAY
   ,HIDE
   ,UPLOAD_TO_TICKET
   ,YA_BUSINESS_GROUPS
} from "../../redux/constants";
import {
    hrPersonsFieldsList
} from '../../redux/actions/hrPersons';
import {
    vendorFieldsList
} from '../../redux/actions/vendorData';
import {
    yandexFieldsList
} from '../../redux/actions/yandex';
import {
    iprocStyles
   ,iprocSubtypes
} from '../../redux/actions/iprocIntegration';
import {
    yesNoList
   //,gLang
} from '../../redux/actions/lang';
import {
    makeUnique
} from "../../redux/actions/utils";

// Как работает Админка:
// build() - отрисовка компоненты на форме Документ,
// admin[] - как выглядит компонента в Админке,
// edit[]  - что находится под иконкой Карандаша "Редакторовать поле" в Админке.
// Например, поле Логин, жму на икону Карандаш, делаю числовым, ставлю Минимальное значение, Максимальное значение, OK -
// смотрим консоль F12 в doc/docTemplates, все атрибуты поля шаблона находятся в плоском списке.
// В ITEM_EDITOR поля копируются для редактирования, чтобы не портить шаблон, если нажмёшь Отмену.
// В createDocParts() списки admin, edit - прописываем, где показывать поля в Админке, сразу на карточке или под Карандашом.

// Под Карандаш можно спрятать только необязательные настройки,
// Тег нельзя, он обязательный!
// Атрибуты под Карандашом по умолчанию не добавляются в шаблон,
// только если пользователь зашёл и изменил там значения.
// Ещё есть механизм экономии - если атрибут под Карандашом равен значению по умолчанию, атрибут удаляется из шаблона (ItemEditor.js onOk).
// Это значит, что нужно прописывать поведение по умолчанию в компоненте.

/*
export const docPartTypes = [
 { subheader: true, name: "Простые компоненты", id: "DateInput" } // id следующей строки, потому что работает клик по subheader
,{ id: "DateInput", name: "Дата" }
,{ id: "Multiline", name: "Многострочное поле" }
,{ id: "Select", name: "Список" }
,{ id: "Text", name: "Статичный текст" }
,{ id: "InputWithSelect", name: "Текст и список" }
,{ id: "Input", name: "Текстовое поле" }
,{ id: "Money", name: "Рубли" }
,{ id: "Checkbox", name: "Checkbox" }
,{ id: "Radio", name: "Radio" }
,{ id: "Subtemplate", name: "Текст из файла Word" }
,{ subheader: true, name: "Функциональные компоненты", id: "HrPersons" }
,{ id: "HrPersons", name: "Сотрудник" }
,{ id: "VendorData", name: "Контрагент" }
,{ id: "Yandex", name: "Компания группы Яндекс" }
,{ id: "SaveAndTicket", name: "Сохранить документ и создать тикет" }
,{ id: "PdfUserPassword", name: "Пароль для чтения PDF" }
,{ id: "StartrekAttachDoc", name: "Сохранить документ в существующий тикет" }
,{ id: "StartrekIntegration", name: "Интеграция с Трекером" }
,{ id: "IprocIntegration", name: "Завести в Я.Покупку" }
,{ id: "MassDocGeneration", name: "Масс-формирование документов" }
,{ id: "Ext", name: "Внешняя форма" }
,{ id: "Lang", name: "Язык" }
];
*/

const docPartTypesCaptions = {
    "ru": {
         subheaderDateInput: "Простые компоненты" // subheader + id следующей строки, чтобы клик по subheader выбрал следующую строку
        ,DateInput: "Дата"
        ,Multiline: "Многострочное поле"
        ,Select: "Список"
        ,Text: "Статичный текст"
        ,InputWithSelect: "Текст и список"
        ,Input: "Текстовое поле"
        ,Money: "Рубли"
        ,Checkbox: "Checkbox"
        ,Radio: "Radio"
        ,Table: "Таблица"
        ,Subtemplate: "Файл"
        ,subheaderHrPersons: "Функциональные компоненты" // subheader
        ,HrPersons: "Сотрудник"
        ,VendorData: "Контрагент"
        ,Yandex: "Компания группы Яндекс"
        ,SaveAndTicket: "Сохранить документ и создать тикет"
        ,PdfUserPassword: "Пароль для чтения PDF"
        ,StartrekAttachDoc: "Сохранить документ в существующий тикет"
        ,StartrekIntegration: "Интеграция с Трекером"
        ,IprocIntegration: "Завести в Я.Покупку"
        ,MassDocGeneration: "Масс-формирование документов"
        ,Ext: "Внешняя форма"
        ,Lang: "Язык"
        ,Uuid: "UUID"
        ,Barcode: "Штрихкод"
        ,Preview: "Preview"
    }
    ,"en": {
         subheaderDateInput: "Plain Components" // subheader
        ,DateInput: "Date"
        ,Multiline: "Multiline"
        ,Select: "Select"
        ,Text: "Plain text"
        ,InputWithSelect: "Input with Select"
        ,Input: "Input field"
        ,Money: "Rubles"
        ,Checkbox: "Checkbox"
        ,Radio: "Radio"
        ,Table: "Table"
        ,Subtemplate: "File"
        ,subheaderHrPersons: "Functional Components" // subheader
        ,HrPersons: "Employee"
        ,VendorData: "Counterparty"
        ,Yandex: "Yandex group company"
        ,SaveAndTicket: "Save document and create ticket"
        ,PdfUserPassword: "PDF reader password"
        ,StartrekAttachDoc: "Save document to existing ticket"
        ,StartrekIntegration: "Tracker integration"
        ,IprocIntegration: "Import to Yandex Procure"
        ,MassDocGeneration: "Mass generation of documents"
        ,Ext: "External form"
        ,Lang: "Language"
        ,Uuid: "UUID"
        ,Barcode: "Barcode"
        ,Preview: "Preview"
    }
};

export const createDocPartTypes = ( lang = "ru" ) => Object.entries( docPartTypesCaptions[ lang ] ).map( e => {
    let id = e[0];
    const name = e[1];
    if( id.startsWith( "subheader" ) ) {
        id = id.substring( "subheader".length );
        return { id, name, subheader: true };
    }
    return { id, name };
});


// если в admin установлено value, его нельзя изменить в Админке
// Тег для Компонентов с особым поведением используется в коде, поэтому имя Тега зарезервировано

// для редактора дополнительных атрибутов поля
const visibility = [
    {
        name: "visibilityParentName"
       ,label: "Поле, управляющее видимостью"
       ,type: "InputWithSelect"
       //,list: список полей создаётся в AdminForm.js onEditItem и передаётся через параметр extra функции build в ItemEditor.jsx
       ,groupBy: option => option.componentLabel // для InputWithSelect, группировка для полей составных компонентов по заголовку компонента
    }
   ,{
        name: "visibilityParentNegate"
       ,label: "не равно"
       ,type: "Checkbox"
       ,defaultValue: false
       ,visibilityParentName: "visibilityParentName"
       ,visibilityParentValue: ""
       ,visibilityParentNegate: true
    }
   ,{
        name: "visibilityParentValue"
       ,label: "Значение управляющего поля"
       ,helperText: "Значение управляющего поля, при котором текущее поле будет отображено." // Для чекбокса можно указать true или false.
       ,type: "Multiline"
       ,visibilityParentName: "visibilityParentName"
       ,visibilityParentValue: ""
       ,visibilityParentNegate: true
    }
];

const required = {
    name: "required"
   ,label: "Обязательное поле"
   ,type: "Checkbox"
   ,defaultValue: true
};

const readonly = {
    name: "readonly"
   ,label: "Только чтение"
   ,type: "Checkbox"
   ,defaultValue: false
};

const tooltip = {
    name: "tooltip"
   ,label: "Подсказка"
   ,type: "Multiline"
};

// для Select и InputWithSelect - запретить ввод новых данных, разрешить выбор из списка,
// для остальных компонентов - как readonly
const selectonly = {
    name: "selectonly"
   ,label: "Запретить ввод новых данных, разрешить выбор из списка"
   ,type: "Checkbox"
   ,defaultValue: false
};

const isRowGroup = {
    name: "isRowGroup"
   ,label: "Группировать в строку со следующим полем"
   ,type: "Checkbox"
   ,defaultValue: false
};
                
// принцип такой:
// !visible - значит поля нет, его значение не определено,
// protect = hide - поле есть, но скрыто от пользователя, используется только для внешних форм

// на практике visible используют,
// чтобы выбирать варианты компонента Yandex с одинаковыми тегами, но разным списком доступных организаций,
// была ошибка, когда кривое значение поля сохранилось в localState, потом это поле скрыли

const protect = {
    name: "protect"
   ,label: "Поле на внешней форме"
   ,type: "Radio"
   ,list: [
        { id: NORMAL, name: "Обычное" }
       ,{ id: GRAY,   name: "Только чтение" }
       ,{ id: HIDE,   name: "Скрытое" }
    ]
   ,defaultValue: NORMAL
   ,helperText: "Скрытые поля не защищают данные.\nНе отдавайте вовне логин, email и прочие секреты."
};


// Проверка значения поля, управляющего видимостью
export const visibilityCheck = ( state, item ) => {
 
    const visibilityParentName = item.visibilityParentName;
    if( visibilityParentName === undefined || visibilityParentName === null || visibilityParentName === '' ) return true; // здесь дубль условия, нужен для ItemEditor.js
    let value = state[ visibilityParentName ];
    if( value === undefined || value === null ) value = '';

    let pattern = item.visibilityParentValue;
    
    // lang - добавляем перевод для "Значения поля, управляющего видимостью"
    /* OLD VERSION
    if( lang !== 'ru' ) {
        const trans = item[ "visibilityParentValue_" + lang ];
        if( trans !== undefined ) pattern = trans;
    } */
    
    if( pattern === undefined || pattern === null ) pattern = '';
    
    const visibilityFlag = item.visibilityParentNegate ?
        String( value ) !== String( pattern ) :
        String( value ) === String( pattern );
    
    return visibilityFlag;
};


// если управляющее поле само скрыто, то и зависимое тоже скрывается
const isVisible = ( state, extra, props ) => {
 
    // lang - добавляем перевод OLD VERSION
    //const lang = gLang ?? "ru";
    // не слишком хорошо работал вариант const lang = props.session.lang ?? "ru";

    // Проверка значения поля, управляющего видимостью
    
    let visibilityParentName = extra.visibilityParentName;
    if( visibilityParentName === undefined || visibilityParentName === null || visibilityParentName === '' ) return true; // не удалять
    
    if( !visibilityCheck( state, extra ) ) return false;
    
    // Если есть поле, управляющее видимостью,
    // пробежать до корневого родителя, добавляя поля в массив, если их там ещё нет - для защиты от цикла.
    // У каждого проверить видимость, выйти, если поле невидимое.

    const visArray = [ visibilityParentName ];
    const doc = props ? props.doc : null;
    const docTemplates = doc ? doc.docTemplates : null;
    const docTemplate = docTemplates ? docTemplates[ doc.docId ] : null;

    const findInTemplate = name => {
        return docTemplate.find( i => i.name === name );
    };
  
    while( docTemplate ) {

        const item = findInTemplate( visibilityParentName );
        if( !item ) break;
        
        visibilityParentName = item.visibilityParentName;
        if( visibilityParentName === undefined || visibilityParentName === null || visibilityParentName === '' ) break;
        if( visArray.includes( visibilityParentName ) ) break;
        
        // Проверка значения поля, управляющего видимостью

        if( !visibilityCheck( state, item ) ) return false;
        
        visArray.push( visibilityParentName );
    }

    return true;
};


// валидаторы хранятся с другими атрибутами шаблона в плоском списке,
// "required": true
// "isNumber": true
// "minNumber": "10000"
// "maxNumber": "1000000"
// "minStringLength": "10"
// "maxStringLength": "10"
// перед прорисовкой поля валидаторы нужно разместить в массивы
// validators: ["required", "isNumber", "minNumber:10000", "maxNumber:1000000", "minStringLength:10", "maxStringLength:10"]
// errorMessages: ["Поле обязательно для заполнения", "Поле должно содержать число", "Минимальное значение 10000", "Максимальное значение 1000000",
// "Минимальная длина строки 10", "Максимальная длина строки 10"]

// 1) получаем установленные свойства
// 2) иначе берём по умолчанию, из редактора дополнительных атрибутов edit
// 3) собираем массивы validators и errorMessages
// 4) если они пустые - вернуть пустые
// 5) если установленные свойства совпадают с умолчаниями, сбросить их в шаблоне - ItemEditor.js onOk()



// createDocParts() вызывается один раз на старте, поэтому в Админке язык не переключается
//let lang = gLang;
//let caption = captions[ lang ] ?? captions[ "ru" ];
let caption; // см. createDocParts() ниже

function getValidators( srcExtra, props ) {
 
    //lang = gLang;
    //caption = captions[ lang ] ?? captions[ "ru" ];

    let extra = { ...srcExtra };

    const docPart = props.doc ? props.doc.docParts[ extra.type ] : '';
    if( !docPart ) return extra;

    const edit = docPart.edit;
    if( !edit ) return extra;
    
    // isNumber - особый случай, получаем заранее
    // для правильного заполнения validators
    let isNumber = extra.isNumber; // получаем установленные свойства
    if( isNumber === undefined ) { // иначе берём по умолчанию, из редактора дополнительных атрибутов edit
        const idx = edit.findIndex( i => i.name === "isNumber" );
        if( idx > -1 ) isNumber = edit[ idx ].defaultValue;
    }

    let validators = [];
    let errorMessages = [];

    // свойства, которые включаются в массив validators
    // для компонентов Material UI, например, для TextValidator,
    // новые пользовательские свойства здесь не нужны
    [ "required"
     ,"isNumber"
     ,"minNumber"
     ,"maxNumber"
     ,"minStringLength"
     ,"maxStringLength"
    ].forEach( key => {
        let value = extra[ key ]; // получаем установленные свойства
        if( value === undefined ) { // иначе берём по умолчанию, из редактора дополнительных атрибутов edit
            const idx = edit.findIndex( i => i.name === key );
            if( idx > -1 ) value = edit[ idx ].defaultValue;
        }
        
        // собираем массивы validators и errorMessages
        switch( key ) {
            case "required":
                if( value !== true ) break;
                validators.push( key );
                errorMessages.push( caption.required );
                break;
            case "isNumber":
                if( value !== true ) break;
                validators.push( key );
                errorMessages.push( caption.isNumber );
                break;
            case "minNumber":
                if( !Number.isInteger( Number.parseInt( value ) ) || isNumber !== true ) break;
                validators.push( key + ":" + value );
                errorMessages.push( caption.minNumber + value );
                break;
            case "maxNumber":
                if( !Number.isInteger( Number.parseInt( value ) ) || isNumber !== true ) break;
                validators.push( key + ":" + value );
                errorMessages.push( caption.maxNumber + value );
                break;
            case "minStringLength":
                if( !Number.isInteger( Number.parseInt( value ) ) ) break;
                validators.push( key + ":" + value );
                errorMessages.push( caption.minStringLength + value );
                break;
            case "maxStringLength":
                if( !Number.isInteger( Number.parseInt( value ) ) ) break;
                validators.push( key + ":" + value );
                errorMessages.push( caption.maxStringLength  + value );
                break;
            default: break;
        }
    } );
    
    return {
        ...extra
       ,validators
       ,errorMessages
    };
}


const defaultValue = ( props, state, extra ) => {
    
    // TODO перенести в Money
    // Деньги редактируем в поле со специальным именем TAG_PREFIX + extra.name,
    // а результат будет в скрытом поле с именем extra.name, "число (прописью) рублей"
    const name = extra.type === "Money" ? TAG_PREFIX + extra.name : extra.name;
    
    if( name === undefined ) return '';
    
    let value = state[ name ];
    if( value !== undefined ) return value;

    value = extra.defaultValue;
    
    // lang - добавляем перевод для "Значение по умолчанию" на форме
    // идея в том, что если мы выбрали хранить переводы в шаблоне,
    // нужно добавить поле с суффиксом языка, defaultValue_en и т.п.
    /* OLD VERSION
    const lang = extra.lang ?? "ru";
    if( lang !== 'ru' ) {
        const trans = extra[ "defaultValue_" + lang ];
        if( trans !== undefined ) value = trans;
    }*/
    
    if( value === undefined ) return '';

    // TODO перенести в IprocIntegration
    // полю "Завести в Я.Покупку" может быть задано значение по умолчанию,
    // нужно установить зависимое значение Подтип
    if( name === "iprocStyle" )
        props.onNameValueChange( "iprocSubtype", extra.additionalDefaultValue );

    props.onNameValueChange( name, value );
    return value;
}

/* Интеграция со Startrek, OEBS-38281
Заменяем переменные в тексте на значения, пришедшие с формы

перенёс в backend - TicketController.java, create(),
1) на фронте replaceTagsWithValues() вызывался при каждом обновлении (на каждое нажатие клавиши в поле ввода), это не нужно,
2) в back приходят имя и код шаблона из hidden inputs в DocForm.jsx, не придётся следить за ними на фронте (если шаблон новый, если изменили имя или код шаблона),
3) можно и с фронта вызывать, в момент отправки формы, поэтому не удаляю

Пример работы:

text =
**Нужен бумажный экземпляр:** <?paper?>
**Размер штрафа:** <?fineAmount?>
**Срок действия NDA:** <?ndaTerm?>

return =
**Нужен бумажный экземпляр:** // тут по смыслу должен быть "Нет", но будет пусто
**Размер штрафа:** 123456
**Срок действия NDA:** 3 (трёх) лет

const replaceTagsWithValues = ( text, state ) => {
    if( text ) {
        const regex = /<\?(.*?)\?>/g; // find tags like <?tag_name?>
        let replaceArray = text.match( regex );

        if( replaceArray ) {
            for( let i = 0; i < replaceArray.length; i++ ) {
                replaceArray[ i ] = replaceArray[ i ].replace( "<?", "" ).replace( "?>", "" )
            }
            for( let i = 0; i < replaceArray.length; i++ ) {
                //Значение для поля ищем в state[ name ], если нет, то провереяем есть ли state[ TAG_PREFIX + name ]
                text = text.replace(
                    "<?" + replaceArray[ i ] + "?>"
                   ,replaceArray[ i ] ?
                        state[ replaceArray[ i ] ] ?
                            state[ replaceArray[ i ] ]
                          : state[ TAG_PREFIX + replaceArray[ i ] ] ?
                                state[ TAG_PREFIX + replaceArray[ i ] ] : ""
                      : ""
                );
            }
        }
    }
    return text;
}
*/


const captions = {
    "ru": {
        // validatorCaptions
        required: "Поле обязательно для заполнения"
       ,isNumber: "Поле должно содержать число"
       ,minNumber: "Минимальное значение "
       ,maxNumber: "Максимальное значение "
       ,minStringLength: "Минимальная длина строки "
       ,maxStringLength: "Максимальная длина строки "
       ,noSpaces: "Без пробелов"
       ,badTag: "Неправильный формат тега"
       
       // createDocParts
       ,label: "Название"
       ,tag: "Тег"
       ,type: "Тип"
       ,number: "Числовое поле"
       ,defaultValue: "Значение по умолчанию"
       ,dateFormatError: "Ошибка в формате даты"
       
       // подсказки к типу компонента
       ,labelSelect: "Тип - Выпадающий список"
       ,labelInput: "Тип - Поле для ввода одной строки"
       ,labelMoney: "Тип - Денежная сумма числом и прописью"
       ,labelDate: "Тип - Поле даты с календарём"
       ,labelMultiline: "Тип - Многострочное поле ввода"
       ,labelInputWithSelect: "Тип - Выбор из списка, с возможностью редактирования"
       ,labelText: "Тип - Текст без возможности редактирования"
       ,labelCheckbox: "Тип - Выбор Да/Нет"
       ,labelRadio: "Тип - Переключатель, выбор одного пункта из списка"
       ,labelTable: "Тип - Таблица, названия колонок задаются Списком *"
       ,labelSubtemplate: "Тип - Файл *"
       ,labelVendorData: "Тип - Составной компонент для ввода данных контрагента *"
       ,labelSaveAndTicket: "Тип - Сохранить документ и создать тикет *"
       ,labelPdfUserPassword: "Тип - Пароль для открытия PDF в режиме чтения"
       ,labelHrPersons: "Тип - Личные данные"
       ,labelMassDocGeneration: "Тип - Масс-формирование документов *"
       ,labelExt: "Тип - Возможность открыть форму внешнему пользователю"
       ,labelLang: "Тип - Язык *"
       ,labelUuid: "Тип - Уникальный идентификатор *"
       ,labelBarcode: "Тип - Текстовое значение, которое будет преобразовано в штрихкод *"
       ,labelPreview: "Тип - Предпросмотр документа"
       
       // комментарии к типу компонента

    }
   ,"en": {
        // validatorCaptions
        required: "Required field"
       ,isNumber: "The field must contain a number"
       ,minNumber: "Minimum value "
       ,maxNumber: "Maximum value "
       ,minStringLength: "Minimum string length "
       ,maxStringLength: "Maximum string length "
       ,noSpaces: "No spaces"
       ,badTag: "Invalid tag format"
       
       // createDocParts
       ,label: "Label"
       ,tag: "Tag"
       ,type: "Type"
       ,number: "Number field"
       ,defaultValue: "Default value"
       ,dateFormatError: "Date format error"
       
       // подсказки к типу компонента
       ,labelSelect: "Type - Drop-down list"
       ,labelInput: "Type - One line input field"
       ,labelMoney: "Type - Money amount in numbers and words"
       ,labelDate: "Type - Date field with calendar"
       ,labelMultiline: "Type - Multiline input field"
       ,labelInputWithSelect: "Type - Drop-down list with the input field"
       ,labelText: "Type - Text without editing option"
       ,labelCheckbox: "Type - Yes/No switch"
       ,labelRadio: "Type - Switch, select one item from a list"
       ,labelTable: "Type - Table, column names are specified in a list *"
       ,labelSubtemplate: "Type - File *"
       ,labelVendorData: "Type - Composite component for entering counterparty data *"
       ,labelSaveAndTicket: "Type - Save document and create ticket *"
       ,labelPdfUserPassword: "Type - Password to open PDF in reading mode"
       ,labelHrPersons: "Type - Personal data"
       ,labelMassDocGeneration: "Type - Mass generation of documents *"
       ,labelExt: "Type - Ability to open a form to an external client"
       ,labelLang: "Type - Language *"
       ,labelUuid: "Type - Unique identifier *"
       ,labelBarcode: "Type - The text value that will be converted to a barcode *"
       ,labelPreview: "Type - Preview document"
       
       // комментарии к типу компонента
       
    }
};



export function createDocParts( lang = "ru" ) {
 
    caption = captions[ lang ] ?? captions[ "ru" ];

    return {
     
     //
     // простые компоненты
     //
     
        // Список
        Select: {
            isList: true
            // можно ещё задать optionsList - полный список значений, доступных для отбора в список list
           ,admin: [ // представление компонента для админки
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelSelect // Тип - Выпадающий список
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Select"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
               ,{
                    type: "Select"
                   ,label: caption.defaultValue // "Значение по умолчанию"
                   ,name: "defaultValue"
                   ,useListFromTemplate: true
                   ,validators: []
                }
            ]
           ,edit: [
                // Карандаш - редактор дополнительных атрибутов поля в админке
                // AdminForm.js onEditItem() - перегнать атрибуты в редактор,
                // ItemEditor.js onOk() - перегнать атрибуты из редактора в шаблон
                ...visibility
               ,tooltip
               ,required
               ,readonly
               ,isRowGroup
            ]
           ,ext: [
                protect
            ]
           ,build: 
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );

                return Select({
                    className: styles.xxSelect
                   ,value
                    // можно задать list, listName, globalListName в extra
                    // list = [ массив ]
                    // listName - имя массива в state текущего документа
                    // globalListName - имя массива в глобальной области state.doc
                   ,list: ( !props.globalLists || !extra.globalListName ) ? state[ extra.listName ] : props.globalLists[ extra.globalListName ]
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                });
            }
        }
        
        
       // Текстовое поле
       ,Input: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelInput // Тип - Поле для ввода одной строки
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Input"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
               //,readonly // TODO тогда нужно ещё значение по умолчанию добавить
               ,isRowGroup
               ,{
                    name: "isNumber"
                   ,label: caption.number // Числовое поле
                   ,type: "Checkbox"
                   ,defaultValue: false
                }
               ,{
                    name: "minNumber"
                   ,label: "Минимальное значение"
                   ,type: "Input"
                   ,visibilityParentName: "isNumber"
                   ,visibilityParentValue: "true"
                   ,validators: ["isNumber"]
                   ,errorMessages: [ caption.isNumber ]
                   ,isRowGroup: true
                }
               ,{
                    name: "maxNumber"
                   ,label: "Максимальное значение"
                   ,type: "Input"
                   ,visibilityParentName: "isNumber"
                   ,visibilityParentValue: "true"
                   ,validators: ["isNumber"]
                   ,errorMessages: [ caption.isNumber ]
                }
               ,{
                    name: "minStringLength"
                   ,label: "Минимальная длина строки"
                   ,type: "Input"
                   ,validators: ["isNumber"]
                   ,errorMessages: [ caption.isNumber ]
                   ,isRowGroup: true
                }
               ,{
                    name: "maxStringLength"
                   ,label: "Максимальная длина строки"
                   ,type: "Input"
                   ,validators: ["isNumber"]
                   ,errorMessages: [ caption.isNumber ]
                }
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );

                return Input({
                    className: styles.xxTextField
                   ,value
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,showPassword: props.showPassword
                   ,onClickShowPassword : props.onClickShowPassword
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                })
            }
        }
        
        
        // Рубли
        // в helperText выводится сумма прописью
       ,Money: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelMoney // Тип - Денежная сумма числом и прописью
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Money"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
               //,readonly // TODO тогда нужно ещё значение по умолчанию добавить
               ,isRowGroup
               ,{
                    name: "isNumber"
                   ,label: "Числовое поле"
                   ,type: "Checkbox"
                   ,defaultValue: true
                   ,readonly: true
                }
               ,{
                    name: "minNumber"
                   ,label: "Минимальное значение"
                   ,type: "Input"
                   ,visibilityParentName: "isNumber"
                   ,visibilityParentValue: "true"
                   ,validators: ["isNumber"]
                   ,errorMessages: [ caption.isNumber ]
                   ,isRowGroup: true
                }
               ,{
                    name: "maxNumber"
                   ,label: "Максимальное значение"
                   ,type: "Input"
                   ,visibilityParentName: "isNumber"
                   ,visibilityParentValue: "true"
                   ,validators: ["isNumber"]
                   ,errorMessages: [ caption.isNumber ]
                }
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );
                const spellValue = state[ extra.name ];

                return Money({
                    className: styles.xxTextField
                   ,value
                   ,spellValue
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,validators: []
                   ,errorMessages: []
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                })
            }
        }
        
        
        // Дата
       ,DateInput: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelDate // Тип - Поле даты с календарём
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "DateInput"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                   ,helperText: "Дополнительно в шаблоне документа можно использовать теги Тег_dd, Тег_mm и Тег_yyyy."
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
               ,readonly // TODO тогда нужно ещё значение по умолчанию добавить?
               ,isRowGroup
               ,{
                    name: "isDefaultDate"
                   ,label: "Текущая дата по умолчанию"
                   ,type: "Checkbox"
                   ,defaultValue: true
                }
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );

                const isExternal = props.user ? props.user.isExternal : false;
                const readonly = extra.readonly || ( isExternal && extra.protect === GRAY );
                if( !readonly ) {
                    extra.validators.push( "date" );
                    extra.errorMessages.push( caption.dateFormatError );
                }
                
                return DateInput({
                    className: styles.xxTextField
                   ,value: state[ extra.name ]
                   ,value_dd: state[ extra.name + "_dd" ]
                   ,value_mm: state[ extra.name + "_mm" ]
                   ,value_yyyy: state[ extra.name + "_yyyy" ]
                   ,onChange: onChangeDate( extra.name, props.onNameValueChange )
                   ,visible: isVisible( state, extra, props )
                   ,isExternal
                   ,...extra
                   ,readonly
                })
            }
        }
        
        
        // Многострочное поле
       ,Multiline: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelMultiline // Тип - Многострочное поле ввода
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Multiline"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
               //,readonly // TODO тогда нужно ещё значение по умолчанию добавить
               ,isRowGroup
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );

                return Multiline({
                    className: styles.xxTextField
                   ,value
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                });
            }
        }
        
        
        // Текст и список
       ,InputWithSelect: {
            isList: true
           ,admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelInputWithSelect // Тип - Выбор из списка, с возможностью редактирования
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "InputWithSelect"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
               ,{
                    type: "InputWithSelect"
                   ,label: "Значение по умолчанию"
                   ,name: "defaultValue"
                   ,useListFromTemplate: true
                   ,validators: []
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
               ,selectonly
               ,readonly
               ,isRowGroup
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                //if( extra.list === undefined ) extra.list = [];
                const value = defaultValue( props, state, extra );

                return InputWithSelect({
                    className: styles.xxSelect
                   ,value
                    // можно задать list, listName, globalListName в extra
                    // list = [ массив ]
                    // listName - имя массива в state текущего документа
                    // globalListName - имя массива в глобальной области doc
                   ,list: extra.list ? extra.list :
                        ( props.globalLists && extra.globalListName ) ? props.globalLists[ extra.globalListName ] :
                         state[ extra.listName ] ? state[ extra.listName ] : []
                   ,onInputChange: f=>f // реакция на печать в поле ввода
                   ,onSelectChange: value => props.onNameValueChange( extra.name, value ) // выбор из списка
                   ,onChange: props.onChange // чтобы можно было впечатать новое значение
                   ,visible: isVisible( state, extra, props )
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                });
            }
        }

        
        // Статичный текст
       ,Text: {
            admin: [
                {
                    type: "Multiline"
                   ,label: "Текст"
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                },
                {
                    name: "type"
                   ,label: caption.labelText // Тип - Текст без возможности редактирования
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Text"
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,isRowGroup
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, extra, styles = defaultStyles ) => {

                return Text({
                    className: styles.xxText
                   ,visible: isVisible( state, extra, props )
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                });
            }
        }
        
        
       ,Checkbox: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelCheckbox // Тип - Выбор Да/Нет
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Checkbox"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
               ,{
                    type: "Select"
                   ,label: "Значение по умолчанию"
                   ,name: "defaultValue"
                   ,list: yesNoList
                   ,defaultValue: false
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               //,required // достаточно установить значение по умолчанию
               ,readonly
               ,isRowGroup
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, extra, styles = defaultStyles ) => {
                const value = defaultValue( props, state, extra );
                
                return Checkbox({
                    value
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                });
            }
        }
        
        
       ,Radio: {
            isList: true
           ,admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelRadio // Тип - Переключатель, выбор одного пункта из списка
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Radio"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
               ,{
                    type: "Select"
                   ,label: "Значение по умолчанию"
                   ,name: "defaultValue"
                   ,useListFromTemplate: true
                   ,validators: []
                }
            ]
           ,edit: [
                ...visibility
               ,{
                    type: "Radio"
                   ,label: "Расположение"
                   ,name: "direction"
                   ,list: [ { id: "row", name: "Строка" }, { id: "col", name: "Колонка" } ]
                   ,defaultValue: "row"
                }
               ,tooltip
               ,required
               ,readonly
               ,isRowGroup
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );
                
                return Radio({
                    className: extra.direction ?? "row"
                   ,value
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,isExternal: props.user ? props.user.isExternal : false
                   //,required: extra.required === false ? false : true
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,...extra
                });
            }
        }
        
        
       ,Table: {
            isList: true // список заголовков для колонок, с возможностью редактирования в Админке, изначально пустой
           ,admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelTable // Тип - Таблица, названия колонок задаются Списком *
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Table"
                   ,helperText: "* Заполните список колонок. Место вставки в шаблоне docx обозначьте {{тегом}}. Если галка \"Отрисовать шапку\" снята, создайте таблицу в шаблоне docx, с шапкой "
                    + "и одной пустой строкой для данных. {{Тег}} поставьте в первую ячейку пустой строки. Формулы Word не работают в режиме \"Предпросмотр документа\"."
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
               ,{
                    type: "Checkbox"
                   ,label: "Отрисовать шапку"
                   ,name: "drawHeader"
                   ,defaultValue: true
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );

                return Table({
                    className: styles.xxTextField
                   ,value
                   ,dispatch: props.dispatch // для onChangeTable()
                   //,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,validators: [ "required" ]
                   ,errorMessages: [ caption.required ]
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                });
            }
        }
        
        
        // Файл
       ,Subtemplate: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                   ,defaultValue: "Файл"
                }
               ,{
                    name: "type"
                   ,label: caption.labelSubtemplate // Тип - Файл *
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Subtemplate"
                   ,helperText: "* Содержимое файла будет вставлено в документ. Место вставки в шаблоне обозначьте тегом {{Тег}}."
                              + " Можно загружать файлы .docx, .doc, .rtf, .xlsx, .xls, .png, .jpg, .jpeg"
                   // "* Место вставки в шаблоне обозначьте тегом {{Тег}} для docx или <?call-template:Тег?> для rtf."
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
            ]
           ,edit: [
                ...visibility
               //,tooltip // не придумал, как скрыть tooltip, выводится всегда, т.к. нужен тег file для Publisher
               ,required
               ,isRowGroup
            ]
           ,ext: [
                {
                    ...protect
                   ,list: protect.list.filter( i => i.id !== GRAY )
                }
               ,{
                    label: "Это поле нельзя предзаполнить для внешнего пользователя. Файл загружается с локального компьютера в момент отправки формы."
                   ,type: "Text"
                }
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );

                return Subtemplate({
                    className: styles.xxSubtemplateName
                   ,readonly: true
                   ,value
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                })
            }
        }

        
     //
     // компоненты с особым поведением
     //
     
     
       // Компания группы Яндекс
       ,Yandex: {
            isList: true
           //,optionsList: "yaOrgs" // полный список значений, доступных для отбора в список list - массив [] или имя массива в props.doc
            // optionsList - справочник для заполнения всех полей составной компоненты, список строк
            // fieldsList - для выбора полей, список столбцов
            ,admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,defaultValue: "Компания группы Яндекс"
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.type
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Yandex"
                }
               ,{
                    type: "Input"
                   ,label: "Суффикс для тегов - нужен, если компонентов больше одного"
                   ,name: "suffix"
                   ,validators: []
                }
               ,{
                    name: "fieldsList"
                   ,label: "Список полей"
                   ,type: "MultiSelect"
                   ,list: yandexFieldsList
                   ,defaultValue: [ "ya_name", "ya_inn", "ya_address" ]
                }
               ,{
                    name: "optionsList"
                   ,label: "Тип списка"
                   ,type: "Radio"
                   ,list: [ { id: "yaOrgs", name: "Компании" }, { id:"yaBGs", name:"Страны" } ]
                   ,direction: "row"
                   ,defaultValue: "yaOrgs"
                }
               ,{
                    type: "Select"
                   ,label: "Значение по умолчанию"
                   ,name: "defaultValue"
                   ,useListFromTemplate: true
                   ,validators: []
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
               ,readonly
               ,selectonly
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                //const value = defaultValue( props, state, extra ); // перенёс в Yandex.jsx

                return Yandex({
                    className: styles.xxTextField
                   //,value
                   ,visible: isVisible( state, extra, props )
                   //,optionsList: extra.listType === 'bg' ? props.doc.yaBGs : props.doc.yaOrgs
                   ,globalLists: props.doc
                   ,state // state шаблона, чтобы установить value всех полей составной компоненты
                   ,session: props.session // в session хранятся данные формы, которые не нужно отправлять, например, списки банковских реквизитов
                   ,onChange: props.onChange
                   ,onNameValueChange: props.onNameValueChange // для DateInput
                   ,dispatch: props.dispatch // для defaultValue()
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                    // fieldsList приходит из шаблона в extra
                })
            }
        }

        
        // Контрагент
       ,VendorData: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,defaultValue: "Контрагент"
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelVendorData // Тип - Составной компонент для ввода данных контрагента *
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "VendorData"
                   ,helperText: "* отделения контрагента зависят от orgId или ya_org_id Компании группы Яндекс"
                }
               ,{
                    type: "Input"
                   ,label: "Суффикс для тегов - нужен, если компонентов больше одного"
                   ,name: "suffix"
                   ,validators: []
                }
               ,{
                    name: "fieldsList"
                   ,label: "Список полей"
                   ,type: "MultiSelect"
                   ,list: vendorFieldsList
                   ,defaultValue: [ "vendorInn", "vendor", /*"spark", "refinitiv",*/ "vendorAddress" ]
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
               ,readonly // настоящий readonly, только чтение
               ,selectonly // для Select и InputWithSelect можно выбирать только из списка, новые данные вносить нельзя, (TODO? для остальных компонентов - как readonly)
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                //const suffix = extra.suffix === undefined || extra.suffix === null ? '' : extra.suffix;
                const isExternal = props.user ? props.user.isExternal : false;
                
                // не показывать предупреждение внешнему пользователю
                // Т.к. CDOC не стал начальной точкой в процессе закупок, можно не обращать внимания на эту проверку. Поставщика можно проверить при заведении в ОЕБС.
                // Скорее всего мы уберем проверки о благонадежности совсем.
                //const isRisky = isExternal ? false : ( state[ 'refinitiv' + suffix ] === "Находится под санкциями" );

                return Vendor({
                    className: styles.xxTextField
                   //,value
                   ,state // state шаблона, чтобы установить value всех полей составной компоненты
                   ,vendorNameList: props.session.vendorNameList
                   ,vendorStates: props.doc.vendorStates
                   ,onChange: props.onChange
                   ,onNameValueChange: props.onNameValueChange // для InputWithSelect
                   ,dispatch: props.dispatch
                   ,visible: isVisible( state, extra, props )
                   //,helperText: isRisky ? "Сотрудничество с этим партнёром может повлечь серьёзные риски для компании, обратитесь к юристам." : ''
                   //,error: isRisky
                   ,cdocProperties: props.cdocProperties
                   ,isExternal: isExternal
                   ,...extra
                    // fieldsList приходит из шаблона в extra
                });
            }
        }
        
        
        // Сохранить документ и создать тикет
       ,SaveAndTicket: {
            admin: [
                {
                    name: "type"
                   ,label: caption.labelSaveAndTicket // Тип - Сохранить документ и создать тикет *
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "SaveAndTicket"
                   ,helperText: "* не более одного поля этого типа на форме"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,defaultValue: UPLOAD_TO_TICKET
                   ,readonly: true
                   ,visible: false // скроем Тег, чтобы не занимал место
                }
               ,{
                    type: "Input"
                   ,label: "Очередь Трекера"
                   ,name: "startrekQueue"
                   ,defaultValue: STARTREK_QUEUE_DEFAULT
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,isRowGroup
               ,{
                    name: "isDocPdf"
                   ,label: "Сохранить PDF"
                   ,type: "Checkbox"
                   ,defaultValue: true
                   ,isRowGroup: true
                }
               ,{
                    name: "isDocRtf"
                   ,label: "Сохранить документ"
                   ,type: "Checkbox"
                   ,defaultValue: true
                }
               ,{
                    name: "isTicketPdf"
                   ,label: "Тикет с PDF"
                   ,type: "Checkbox"
                   ,defaultValue: true
                   ,isRowGroup: true
                }
               ,{
                    name: "isTicketRtf"
                   ,label: "Тикет с документом"
                   ,type: "Checkbox"
                   ,defaultValue: true
                }
               ,{
                    name: "isProtected"
                   ,label: "Защита редактирования документа"
                   ,type: "Checkbox"
                   ,defaultValue: false
                }
               ,{
                    name: "pdfAdminPassword"
                   ,label: "Пароль для редактирования документа *"
                   ,isPassword: true
                   ,visibilityParentName: "isProtected"
                   ,visibilityParentValue: "true"
                   ,type: "Input"
                   ,helperText: "* для RTF можно использовать только латиницу"
                }

               // документ без пароля
               ,{
                    name: "isDocPdfNopass"
                   ,label: "Сохранить PDF без пароля"
                   ,type: "Checkbox"
                   ,defaultValue: false
                   ,visibilityParentName: "isProtected"
                   ,visibilityParentValue: "true"
                   ,isRowGroup: true
                }
               ,{
                    name: "isDocRtfNopass"
                   ,label: "Сохранить документ без пароля"
                   ,type: "Checkbox"
                   ,defaultValue: false
                   ,visibilityParentName: "isProtected"
                   ,visibilityParentValue: "true"
                }
               ,{
                    name: "isTicketPdfNopass"
                   ,label: "Тикет с PDF без пароля"
                   ,type: "Checkbox"
                   ,defaultValue: false
                   ,visibilityParentName: "isProtected"
                   ,visibilityParentValue: "true"
                   ,isRowGroup: true
                }
               ,{
                    name: "isTicketRtfNopass"
                   ,label: "Тикет с документом без пароля"
                   ,type: "Checkbox"
                   ,defaultValue: false
                   ,visibilityParentName: "isProtected"
                   ,visibilityParentValue: "true"
                }
                
                // - сохранить и тикет (Word)
                // - cохранить и тикет (PDF)
                // - тикет с Word и PDF (PDF с паролем, Word - без)

                // в таблице xxya.cdoc_ext есть привязка uuid к ticket;
                // если нет uuid или ticket is null, создаём тикет,
                // если есть uuid и ticket, то прикладываем файлы в тикет
               ,{
                    name: "isDocAndTicketPdf"
                   ,label: "Создать тикет и сохранить файл PDF"
                   ,type: "Checkbox"
                   ,defaultValue: false
                   ,isRowGroup: true
                }
               ,{
                    name: "isDocAndTicketRtf"
                   ,label: "Создать тикет и сохранить файл Word"
                   ,type: "Checkbox"
                   ,defaultValue: false
                }
               ,{
                    name: "isTicketPdfRtfNopass"
                   ,label: "Создать тикет (PDF с паролем и Word - без)"
                   ,type: "Checkbox"
                   ,defaultValue: false
                }
               ,{
                    name: "isUploadFile"
                   ,label: "Приложить файл в тикет"
                   ,type: "Checkbox"
                   ,defaultValue: false
                }

            ]
           ,ext: [
                {
                    ...protect
                   ,helperText: ''
                   ,list: protect.list.filter( i => i.id !== GRAY )
                }
            ]
           ,build:
            ( props, state, extra, styles = defaultStyles ) => {
                const value = defaultValue(props, state, extra);
                
                // нет validators, "Выбрать файл" - необязательное поле
                
                return SaveAndTicket({
                    className: styles.xxSubtemplateName
                   ,readonly: true
                   ,onChange: props.onChange
                   ,onSaveDoc: props.onSaveDoc
                   ,onCreateTicket: props.onCreateTicket
                   ,onDocAndTicket: props.onDocAndTicket
                   ,onTicketPdfRtfNopass: props.onTicketPdfRtfNopass
                   ,onCreateTicketWithFile: props.onCreateTicketWithFile
                   ,value
                   ,visible: isVisible( state, extra, props )
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                });
            }
        }
        
        
        // Пароль для чтения PDF
       ,PdfUserPassword: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,defaultValue: "Пароль для чтения PDF"
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelPdfUserPassword // Тип - Пароль для открытия PDF в режиме чтения
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "PdfUserPassword"
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,readonly: true
                   ,defaultValue: "pdfUserPassword"
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               //,required // TODO может и не нужно для Пароля
            ]
           ,ext: [
                protect // FYI пароль не передаётся на внешнюю форму
               ,{
                    label: "Пароль не передаётся на внешнюю форму."
                   ,type: "Text"
                }
            ]
           ,build:
            (props, state, srcExtra, styles = defaultStyles) => {
                const extra = getValidators(srcExtra, props);
                const value = defaultValue(props, state, extra);

                return Input({
                    className: styles.xxTextField
                   ,value
                   ,onChange: props.onChange
                   ,onClickShowPassword: props.onClickShowPassword
                   ,isPassword: true
                   ,showPassword: props.doc.showPassword
                   ,visible: isVisible( state, extra, props )
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                });
            }
        }
        
        
        // Сохранить документ в существующий тикет
       ,StartrekAttachDoc: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,defaultValue: "Номер тикета"
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.type
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "StartrekAttachDoc"
                }
               ,{ // не используется в back, только для проверки на front, что тикет принадлежит очереди
                    type: "Input"
                   ,label: "Очередь Трекера *"
                   ,name: "startrekQueueAttach"
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,defaultValue: STARTREK_QUEUE_DEFAULT
                   ,helperText: "* Можно указать несколько очередей через ; или пробел."
                }
               ,{
                    type: "Input"
                   ,label: "Комментарий для Трекера"
                   ,name: "startrekCommentTemplate"
                   ,validators: []
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,readonly: true
                   ,defaultValue: "startrekAttachDoc"
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               //,required // TODO требует заполнения и Номера тикета, и Комментария для Трекера
               ,{
                    name: "isDocPdf"
                   ,label: "PDF"
                   ,type: "Checkbox"
                   ,defaultValue: true
                   ,isRowGroup: true
                }
               ,{
                    name: "isDocRtf"
                   ,label: "Word"
                   ,type: "Checkbox"
                   ,defaultValue: true
                }

               // документ без пароля
               ,{
                    name: "isDocPdfNopass"
                   ,label: "PDF без пароля"
                   ,type: "Checkbox"
                   ,defaultValue: false
                   ,isRowGroup: true
                }
               ,{
                    name: "isDocRtfNopass"
                   ,label: "Word без пароля"
                   ,type: "Checkbox"
                   ,defaultValue: false
                }
                
               ,{
                    label: 'Пароль на редактирование документа можно установить в компоненте "Сохранить документ и создать тикет".'
                   ,type: "Text"
                }
                
               // TODO пароли задаются в SaveAndTicket на редактирование и PdfUserPassword на чтение,
               // я бы объединил StartrekAttachDoc и SaveAndTicket -
               // 1) создать тикет с документом,
               // 2) или приложить документ в существующий тикет,
               // 3) или просто скачать документ
               // или можно вынести пароль из SaveAndTicket в новый компонент
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );
                return StartrekAttachDoc({
                    value
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,onStartrekAttachDoc: props.onStartrekAttachDoc
                   // startrekComment - если пользователь на форме не заполнил, то подставляем тот, который ввёл админ
                   ,startrekComment: state[ "startrekComment" ] !== undefined ? state[ "startrekComment" ] : extra.startrekCommentTemplate
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                });
            }
        }

        
        // OEBS-38281 поля Startrek
       ,StartrekFieldsList: {
            /* невидимая компонента, TODO переместить в StartrekIntegration.jsx */
            build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );
                return InputWithSelect({
                    className: styles.xxSelect
                   ,value
                   ,list: ( !props.startrekFieldsList ? [] : props.startrekFieldsList )
                   ,onFocus: props.onLoadStartrekFieldsList // для заполнения списка startrekFieldsList в момент обращения
                   ,onInputChange: f=>f // реакция на печать в поле ввода
                   ,onSelectChange: value => props.onNameValueChange( extra.name, value ) // выбор из списка
                   ,visible: isVisible( state, extra, props )
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,...extra
                });
            }
        }

        
        // OEBS-38281 поля Startrek
       ,TextWithVariables: {
            /* невидимая компонента, TODO переместить в StartrekIntegration.jsx */
            build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );
                return StartrekIntegration({
                    className: styles.xxSelect
                   ,label: props.label
                   ,value
                   ,list: props.tmplItems
                   ,onFocus: props.onLoadTemplateItemsList // для заполнения списка tmplItems в момент обращения
                   ,onChange: props.onChangeSaveStartSelection
                   ,onInputChange: props.onInputChange
                   ,onSelectChange: ( value ) => props.onSelectedValue( value, extra.name )
                   ,onKeyUp: props.onKeyUp
                   ,visible: props.visible
                   ,...extra
                });
            }
        }

        
        // OEBS-38281 поля Startrek
        // TODO предлагаю сделать 1 компонент "Интеграция с Трекером" на 1 шаблон,
        // textWithVariables и startrekFields - массивы,
        // редактировать в отдельном окне, как списки
        
        // Интеграция с Трекером
       ,StartrekIntegration: {
            admin: [
                /*{
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,*/{
                    name: "type"
                   ,label: caption.type
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "StartrekIntegration"
                }
               ,{
                    name: "startrekFields"
                   ,label: "Поле Трекера"
                   ,type: "StartrekFieldsList"
                }
                // TODO хочу что-нибудь скрыть под Карандаш, чтобы уменьшить карточку,
                // но нужно аккуратно - следить за списками, видимостью, обязательностью полей
               ,{
                    type: "TextWithVariables"
                   ,name: "textWithVariables"
                   ,label: "Текст с переменными *"
                   ,helperText: "* Если нужно указать несколько значений в списочном поле Трекера"
                        + " (например, несколько Наблюдателей), укажите их через запятую."
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,readonly: true
                   ,defaultValue: "startrekIntegration"
                }
            ]
           /*,edit: [
            ]*/
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                // const value = replaceTagsWithValues( extra.textWithVariables, state )

                return HiddenInput({
                    //value: value && extra.startrekFields ? extra.startrekFields.id + "," + value : "" // если используем replaceTagsWithValues()
                    value: extra.startrekFields ? extra.startrekFields.id + "," + extra.textWithVariables : ''
                   ,...extra
                });
            }
        }
        

        // MultiSelect - внутренняя компонента
        // для отображения списка полей в Админке
        // в виде выпадающего списка с чекбоксами,
        // применяется для выбора видимых полей составного компонента,
        // сохраняет список видимых полей в шаблоне
       ,MultiSelect: {
            build: 
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );

                return MultiSelect({
                    className: styles.xxSelect
                   ,value
                    // можно задать list, listName, globalListName в extra
                    // list = [ массив ]
                    // listName - имя массива в state текущего документа
                    // globalListName - имя массива в глобальной области state.doc
                   ,list: ( !props.globalLists || !extra.globalListName ) ? state[ extra.listName ] : props.globalLists[ extra.globalListName ]
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,...extra
                });
            }
        }
        
        
        // OEBS-38884 - Сотрудник
       ,HrPersons: {
            optionsList: "hrPersons" // справочник для заполнения всех полей составной компоненты, список строк
           ,admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,defaultValue: "Сотрудник"
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelHrPersons // Тип - Личные данные
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "HrPersons"
                }
               ,{
                    type: "Input"
                   ,label: "Суффикс для тегов - нужен, если компонентов больше одного *"
                   ,name: "suffix"
                   ,validators: []
                   ,helperText: "* компонента без суффикса по умолчанию отображает данные по логину пользователя"
                }
               ,{
                    name: "fieldsList" // список для выбора видимых полей
                   ,label: "Список полей"
                   ,type: "MultiSelect"
                   ,list: hrPersonsFieldsList
                   ,defaultValue: [ "hr_login", "hr_fio", "hr_birth_date", "hr_address", "id_doc_full" ]
                }
               ,{ // ограничение по БГ на фронте в hrPersons.js/getHrOnePersonDB(),
                  // если форма рассчитана на работу с определёнными БГ
                    name: "limitToBusinessGroups"
                   ,label: "Только выбранные бизнес-группы (если пусто - без ограничения)"
                   ,type: "MultiSelect"
                   ,globalListName: YA_BUSINESS_GROUPS
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
               ,readonly
            ]
           ,ext: [
                {
                    label: "Личные данные нельзя передавать внешним пользователям. Предзаполненные данные будут очищены на внешней форме."
                   ,type: "Text"
                }
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                //const value = defaultValue( props, state, extra );

                return HrPersons({
                    className: styles.xxTextField
                   ,dispatch: props.dispatch
                   //,value
                   ,visible: isVisible( state, extra, props )
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                    // Выпадающий список с полем ввода для Логина,
                    // логины могут повторяться для сотрудника, нанятого в разных БГ
                   ,selectList: makeUnique( props.doc[ "hrPersons" ].map( i => i.hr_login ) )
                   ,state // state шаблона, чтобы установить value всех полей составной компоненты
                   ,onChange: props.onChange
                   ,onNameValueChange: props.onNameValueChange
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                    // fieldsList приходит из шаблона в extra
                })
            }
        }
        
        
        // OEBS-36017 - Завести в Я.Покупку
       ,IprocIntegration: {
            isList: true
           ,optionsList: iprocStyles
           ,admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                   //,defaultValue: "Завести в Я.Покупку"
                }
               ,{
                    name: "type"
                   ,label: caption.type + " *"
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "IprocIntegration"
                   ,helperText: "* Создаёт тикет с документом в указанной очереди. "
                        + "В комментарий добавляется ссылка на Я.Покупку c заполненными атрибутами. "
                        + 'В качестве атрибутов передаются значения полей с тегами vendorInn, orgId или ya_org_id, date, iprocStyle, iprocSubtype, логин пользователя и признак "Шаблонный договор".'
                }
                // TODO хочу что-нибудь скрыть под Карандаш, чтобы уменьшить карточку,
                // но нужно аккуратно - следить за списками, видимостью, обязательностью полей
               ,{
                    type: "Input"
                   ,label: "Очередь Трекера"
                   ,name: "startrekQueueIproc"
                   ,defaultValue: STARTREK_QUEUE_DEFAULT
                    // Input по умолчанию required
                }
               ,{
                    name: "isTemplate"
                   ,label: "Шаблонный договор"
                   ,type: "Checkbox"
                   ,defaultValue: true
                   //,isRowGroup: true
                }
               ,{ // по требованию заказчика должно храниться в шаблоне, но никак не используется
                    type: "Input"
                   ,label: "Тикет с согласованиями"
                   ,name: "ticketNum"
                   ,visibilityParentName: "isTemplate"
                   ,visibilityParentValue: "true"
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                }
               ,{
                    name: "hideIprocStyle"
                   ,label: "Не отображать пользователю выбор стиля ЗП"
                   ,type: "Checkbox"
                   ,defaultValue: false
                }
               ,{
                    name: "defaultValue"
                   ,label: "Значение по умолчанию Стиль ЗП"
                   ,type: "Select"
                   ,list: iprocStyles
                   ,useListFromTemplate: true
                   ,validators: []
                }
               ,{
                    name: "additionalDefaultValue"
                   ,label: "Значение по умолчанию Подтип ЗП"
                   ,type: "Select"
                   ,list: iprocSubtypes
                   ,visibilityParentName: "defaultValue"
                   ,visibilityParentValue: IPROC_SUBTYPE_PROP // Доп. соглашение
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,readonly: true
                   ,defaultValue: "iprocStyle"
                   ,visible: false // Тег скрыт, он здесь нужен только чтобы получить defaultValue для iprocStyle
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,{
                    name: "format"
                   ,label: "Формат документа"
                   ,type: "Radio"
                   ,list: [ { id: "pdf", name: "PDF" }, { id:"rtf", name:"Word" } ]
                   ,defaultValue: "pdf"
                }
            ]
           ,ext: [
                {
                    label: 'Компонент "Завести в Я.Покупку" доступен только пользователям с ролью "Я.Инициатор", и недоступен внешним пользователям.'
                   ,type: "Text"
                }
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => { 
                const extra = getValidators( srcExtra, props );
                const iprocStyleValue = defaultValue( props, state, extra );
                const iprocSubtypeValue = state['iprocSubtype'];

                return  IprocIntegration({
                    className: styles.xxSelect
                   ,iprocStyleValue: iprocStyleValue
                   ,iprocSubtypeValue: iprocSubtypeValue
                   ,subtype: iprocSubtypes
                   ,onChange: props.onChange
                   ,onCreateTicket: props.onCreateTicketIproc
                   ,iproc: state[IPROC]
                   ,orgId: state['orgId'] ? state['orgId'] : state['ya_org_id']
                   ,visible: isVisible( state, extra, props )
                   ,isInitiator: props.user.isInitiator
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,...extra
                })
            }
        }

        
       // OEBS-43135 - Масс-формирование документов
       ,MassDocGeneration: {
            admin: [
                /*{
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,*/{
                    name: "type"
                   ,label: caption.labelMassDocGeneration // Тип - Масс-формирование документов *
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "MassDocGeneration"
                   ,helperText: '* Загрузка файлов для масс-формирования документов осуществляется на закладке "Масс-загрузки"'
                }
               ,{
                    type: "Input"
                   ,label: "Очередь Трекера"
                   ,name: "startrekQueue"
                   ,defaultValue: STARTREK_QUEUE_DEFAULT
                    // Input по умолчанию required
                }
            ]
           ,edit: [
                {
                    name: "format"
                   ,label: "Формат документа"
                   ,type: "Radio"
                   ,list: [ { id: "pdf", name: "Pdf" }, { id:"rtf", name:"Документ" }, { id:"null", name:"Без документа" } ]
                   ,defaultValue: "pdf"
                }
               ,{
                    label: 'По каждой строке файла масс-загрузки формируется тикет с документом, но можно объединить результаты в один тикет с документом:'
                   ,type: "Text"
                }
               ,{
                    name: "mergeDoc"
                   ,label: "Формат объединённого файла"
                   ,type: "Select"
                   ,list: [ { id:"doc", name:"Docx / Pdf" }, { id:"txt", name:"Xml / Csv" }, { id:"zip", name:"Zip" } ]
                }
            ]
           ,build:
                ( props, state, srcExtra ) => {
                    const extra = getValidators( srcExtra, props );

                    return HiddenInput({
                        value: ''
                       ,...extra
                    });
                }
        }
        
        
        // Внешняя форма
       ,Ext: {
            admin: [
                {
                    name: "type"
                   ,label: caption.labelExt // Тип - Возможность открыть форму внешнему пользователю
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Ext"
                }
               ,{
                    label: 'На внешней форме выводится кнопка "Сохранить" (можно скрыть настройкой видимости). '
                         + 'При нажатии состояние формы будет сохранено, а пользователь получит ссылку, которую нужно передать в Яндекс для дозаполнения формы.'
                   ,type: "Text"
                }
            ]
           ,edit: [
                ...visibility
               //,tooltip // не придумал, как скрыть tooltip, выводится всегда из-за Share Icon
            ]
           ,ext: [
                {
                    ...protect
                   ,helperText: ''
                   ,list: protect.list.filter( i => i.id !== GRAY )
                }
            ]
           ,build:
            ( props, state, extra, styles = defaultStyles ) => {

                return Ext({
                    doc: props.doc
                   ,dispatch: props.dispatch
                   ,visible: isVisible( state, extra, props )
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,isIframe: props.user ? props.user.isIframe : false
                   ,...extra
                });
            }
        }
        
        
        // Язык
       ,Lang: {
            admin: [
                {
                    name: "type"
                   ,label: caption.labelLang // Тип - Язык *
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Lang"
                   ,helperText: '* Этот компонент нужен, если вы хотите хранить в одном шаблоне текст на разных языках'
                }
               ,{
                    name: "isLangEn"
                   ,label: "Хранить перевод на английский язык в шаблоне"
                   ,type: "Checkbox"
                   ,defaultValue: false
                   //,readonly: true
                }

            ]
           ,build: () => ''
        }
        
        
       ,Uuid: {
            admin: [
                {
                    name: "type"
                   ,label: caption.labelUuid // Тип - Уникальный идентификатор *
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Uuid"
                   ,helperText: '* Заполняется автоматически, не отображается на форме.'
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }

            ]
           ,build:
            ( props, state, extra, styles = defaultStyles ) => {
                return Uuid({
                    state
                   ,onNameValueChange: props.onNameValueChange
                   ,...extra
                })
            }
        }

        
        // Штрихкод
       ,Barcode: {
            admin: [
                {
                    type: "Multiline"
                   ,label: caption.label
                   ,name: "label"
                   ,bold: true
                   ,validators: []
                }
               ,{
                    name: "type"
                   ,label: caption.labelBarcode // Тип - Текстовое значение, которое будет преобразовано в штрихкод *
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Barcode"
                   ,helperText: '* Работает только в шаблонах DOCX.'
                }
               ,{
                    type: "Input"
                   ,label: caption.tag
                   ,name: "name"
                   ,validators: [ "required", "noSpaces", "badTag" ]
                   ,errorMessages: [ caption.required, caption.noSpaces, caption.badTag ]
                }
               ,{
                    type: "Select"
                   ,label: "Тип штрихкода"
                   ,name: "barcodeType"
                   ,list: [ { id: "EAN_13", name: "EAN-13" }, { id:"DATA_MATRIX", name:"Data Matrix" } ]
                   ,defaultValue: "EAN_13"
                }
            ]
           ,edit: [
                ...visibility
               ,tooltip
               ,required
               //,readonly // TODO тогда нужно ещё значение по умолчанию добавить
               ,isRowGroup
            ]
           ,ext: [
                protect
            ]
           ,build:
            ( props, state, srcExtra, styles = defaultStyles ) => {
                const extra = getValidators( srcExtra, props );
                const value = defaultValue( props, state, extra );

                return Multiline({
                    className: styles.xxTextField
                   ,value
                   ,onChange: props.onChange
                   ,visible: isVisible( state, extra, props )
                   ,validators: ["required"]
                   ,errorMessages: [ caption.required ]
                   ,isExternal: props.user ? props.user.isExternal : false
                   ,dataType: "Barcode" // для отображения Barcode в Preview
                   ,...extra
                });
            }
        }
        
        
        // Предпросмотр документа
       ,Preview: {
            admin: [
                {
                    name: "type"
                   ,label: caption.labelPreview // Тип - Предпросмотр документа
                   ,type: "Select"
                   ,globalListName: "docPartTypes"
                   ,defaultValue: "Preview"
                }
               ,{
                    label: 'На форме Документ появится иконка "Предпросмотр документа" в виде глаза.'
                        + ' Работает только для шаблонов в формате docx.'
                        + ' Убедитесь, что предпросмотр документа выглядит корректно,'
                        + ' иначе - удалите компонент Preview и обратитесь в поддержку.'
                   ,type: "Text"
                }
            ]
           ,build:
            ( props ) => {
                if( !props.doc || !props.user ) return '';
                return PreviewIcon({
                    showPreview: props.doc.showPreview
                   ,isIframe: props.user.isIframe
                   ,isExternal: props.user.isExternal
                   ,docId: props.doc.docId
                   ,docNames: props.doc.docNames
                   ,onClickPreview: props.onClickPreview
                });
            }
        }
        
        
    };
}
