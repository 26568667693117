import React from 'react'
import {withStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ClearRounded from '@material-ui/icons/ClearRounded';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import GroupAddRounded from '@material-ui/icons/GroupAddRounded';
import SupervisedUserCircleRounded from '@material-ui/icons/SupervisedUserCircleRounded';
import { COLOR_MAIN } from '../../constants'

const classes = {
    box: {
        display: "flex"
       ,flexWrap: "wrap"
    }    
   ,avatar: {
        position: "relative"
       ,"&:hover button": {
            visibility: "visible"
        }
    }
   ,clear: {
        position: "absolute"
       ,top: "4px"
       ,right: "4px"
       ,width: "30px"
       ,height: "30px"
       ,visibility: "hidden"
       ,"&:hover": {
            visibility: "visible"
        }
    }
   ,clearIcon: {
        position: "relative"
       ,top: "0px"
       ,right: "0px"
       ,fontSize: "23px"
       ,backgroundColor: "white"
       ,borderRadius: "50%"
       ,opacity: .8
    }
   ,avatarPerson: {
        width: "78px"
       ,height: "78px"
    }
   ,avatarGroup: {
        width: "78px"
       ,height: "78px"
       ,color: COLOR_MAIN
    }
   ,groupName: {
        position: "absolute"
       ,bottom: "0px"
       ,fontSize: "14px"
       ,background: "rgba(255,255,255,0.75)"
    }
};

const RoleList = ( props ) => {

    return (
        <div className={ props.classes.box } >
        { !props.list ? '' :
          props.list.map( (item, key) =>
            <div key={key} className={ props.classes.avatar } >
            
            { item.userType === 'PERSON' ?
                <Tooltip title={ item.userName } arrow enterDelay={ 0 } placement="bottom" >
                <Link href={ "https://staff.yandex-team.ru/" + item.userId } target="_blank" rel="noopener noreferrer" color="inherit">
                <IconButton>
                    <Avatar
                        variant="circle"
                        alt={ item.userName }
                        src={ "https://center.yandex-team.ru/api/v1/user/" + item.userId + "/avatar/100.jpg" }
                        className={ props.classes.avatarPerson }
                    />
                </IconButton>
                </Link>
                </Tooltip>
             :
                <Tooltip title={ item.userName } arrow enterDelay={ 0 } placement="bottom" >
                <Link href={ "https://staff.yandex-team.ru/departments/" + (item.userType === 'ALL' ? '' : item.userId ) } target="_blank" rel="noopener noreferrer" color="inherit">
                <IconButton>
                    { item.userType === 'ALL' ?
                        <GroupAddRounded className={ props.classes.avatarGroup } />
                      :
                        <SupervisedUserCircleRounded className={ props.classes.avatarGroup } />
                    }
                    <span className={ props.classes.groupName }>{ item.userName }</span>
                </IconButton>
                </Link>
                </Tooltip>
            }
            
            { props.onDelete === undefined ? '' :
                <IconButton
                    className={ props.classes.clear }
                    onClick={ () => props.onDelete( item.roleId, item.itemType, item.itemId ) }
                >
                <ClearRounded className={ props.classes.clearIcon } />
                </IconButton>
            }
            
            </div>
        )}
        </div>
    )
}

export default withStyles(classes)(RoleList);
