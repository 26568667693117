import { connect } from 'react-redux';
import React, { Component } from 'react';
import { default as VRoleForm } from '../components/RoleForm/RoleForm';
import {
    docChange
   ,groupChange
   ,load
} from '../redux/actions/admin';
import {
    addRole
   ,addRoleDB
   ,deleteRoleDB
   ,getStaff
} from '../redux/actions/role';
import {
    APP_URL
} from '../redux/constants'
import { downloadFile } from "../redux/actions/doc";
import {
    get_common
   ,post_common
} from '../redux/actions/utils'
import { errorSnackbarLong, successSnackbar } from "../redux/actions/snackbar";


class RoleForm extends Component {
    
    componentDidUpdate( prevProps ) {
        // загрузить шаблон из базы, если его нет
        load( this.props, prevProps );
    }
   
    render() {
        return (
            <VRoleForm
                { ...this.props }
            />
        )
    }
}


const mapStateToProps = ( { doc, role, user } ) => {
    return {
        doc
       ,role
       ,user
    };
}


const mapDispatchToProps = dispatch => ({
    dispatch // передаю dispatch в props, для load
    // prevent firing button on Enter
   ,onFormKeyPress: ( e ) => {
        // allow Enter in textarea
        var txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
        if( txtArea || (e.keyCode || e.which || e.charCode || 0) !== 13 ) {
            return true;
        } else {
            e.preventDefault();
        }
        return false;
    }
    // форму убрать нельзя, Material компоненты привязываются к форме
   ,onFormSubmit: ( e ) => {
        e.preventDefault();
    }
   ,onDocChange: ( e ) => docChange( dispatch, e )
   ,onGroupChange: ( e, oldGroupId ) => groupChange( dispatch, e, oldGroupId )
   ,onStaffInput: ( listName, value ) => {
        getStaff( dispatch, listName, value );
    }
   ,onStaffSelectChange: ( id, listName, value, itemType, accessType, tmpIdSequence ) => {
        if( value === null || value.id === undefined ) return;
        
        const role = {
            roleId: --tmpIdSequence // здесь будет id из базы после сохранения
           ,itemType // GROUP / TEMPLATE
           ,itemId: id // id группы или шаблона
           ,accessType // USER / ADMIN
           ,userType: value.type // PERSON / GROUP / ALL
           ,userId: value.id
           ,userName: value.tooltip
        };

        dispatch( addRole( id, listName, role ) ); // добавить лицо или подразделение в список
        addRoleDB( dispatch, id, listName, role );
    }
   ,onDelete: ( id, name, roleId, itemType, itemId ) => deleteRoleDB( dispatch, id, name, roleId, itemType, itemId )
   // Скачать матрицу ролей
   ,onRoleMatrixDownload: ( e ) => download( e, dispatch, "rolematrix", "Матрица ролей" )
   // Загрузить матрицу ролей
   ,onRoleMatrixUpload: ( e ) => upload( e, dispatch, "rolematrix" )
   // Скачать дерево ролей
   ,onRoleTreeDownload: ( e ) => download( e, dispatch, "roletree", "Дерево ролей" )
   // Загрузить дерево ролей
   ,onRoleTreeUpload: ( e ) => upload( e, dispatch, "roletree" )
});


const download = ( e, dispatch, link, filename ) => {
    e.preventDefault();

    const config = { responseType: 'arraybuffer', headers: { 'content-type': 'multipart/form-data' } };
    get_common(
        APP_URL + "/api/" + link
       ,dispatch
       ,"Ошибка выгрузки файла"
       ,( response, responseFilename ) => {
            downloadFile( response, filename, "xls", responseFilename );
        }
       ,f=>f
       ,null
       ,config
    );
};


const upload = ( e, dispatch, link ) => {
    e.preventDefault();

    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const file = e.target.files[0];
    if( !file ) return;
    const fileName = file.name;
    e.target.value = ''; // reset, to enable same file re-opening
    const formData = new FormData();
    formData.append( 'file', file );
    formData.append( 'fileName', fileName );

    post_common(
        APP_URL + '/api/' + link
       ,formData
       ,config
       ,dispatch
       ,''
       ,data => {
            if( data === 'OK' ) {
                successSnackbar( "Файл загружен" );
            } else {
                errorSnackbarLong( data ?? "Ошибка загрузки файла" );
            }
       }
    );
};

export default connect( mapStateToProps, mapDispatchToProps )( RoleForm )


