import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';

import { ValidatorForm } from 'react-material-ui-form-validator';
import Input from '../DocParts/Input';
import styles from "../AdminParts/Form.module.css"
import Grow from '@material-ui/core/Grow';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const GroupEditor = ( props ) => {
 
    if( !props.open ) return '';

    const onOk = props.onOk === undefined ? props.onCancel : () => { props.onCancel(); props.onOk( props.goal, props.groupId, props.groupName ); }
    
    return (
        <Dialog
            open={ true }
            TransitionComponent={ Transition }
            onBackdropClick={ props.onCancel }
            onEscapeKeyDown={ props.onCancel }
        >
        
            <ValidatorForm
                id="groupEditorForm"
                onSubmit={ onOk }
                autoComplete="off"
                className={ styles.xxForm }
            >

                {/*<DialogTitle>{ props.title ? props.title : "Error" }</DialogTitle>*/}
                <DialogContent>
                    {/*<DialogContentText>{ props.msg }</DialogContentText>*/}
                    
                    <Input
                        className={ styles.xxTextField }
                        label={ "Название группы" }
                        name="groupName"
                        value={ props.groupName }
                        onChange={ props.onChange }
                        autoFocus
                    />

                    
                    
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={ onOk }
                        color="secondary"
                        variant="outlined"
                        size="small"
                    > Ок </Button>
                    { props.onOk === undefined ? '' :
                        <Button onClick={ props.onCancel } color="secondary" variant="outlined" size="small" > Отмена </Button>
                    }
                </DialogActions>
            
            </ValidatorForm>
            
        </Dialog>
    );
};

export default GroupEditor;
