export const newTemplate = [
    {
        name: "login"
       ,label: "Логин"
       ,type: "Input"
    }
   ,{
        name: "date"
       ,label: "Дата"
       ,type: "DateInput"
    }
   ,{
        type: "SaveAndTicket"
       ,startrekQueue: "LEGALTEST"
    }
];
