import React from 'react'
import styles from "./Form.module.css"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ValidatorForm } from 'react-material-ui-form-validator';
import UploadsTable from './UploadsTable';
import UploadsFilter from './UploadsFilter';
import Legend from './Legend';
import {
    getUserDocSelect
   ,getUserGroupSelect
} from '../../redux/actions/admin'
import UploadsActions from "./UploadsActions";
import {statusList} from "../../redux/actions/uploads";

// Список статусов загрузок
export function getProcessingStatusList( props ) {
    return props.doc.docParts.Select.build(
        props
       ,props.doc
       ,{
            label: "Статус"
           ,name: "status"
           ,list: statusList
           ,value: props.uploads.status
           ,onChange: e => props.onChange( e )
        }
    );
}

export function getFileNameInput( props ) {
    return props.doc.docParts.Input.build(
        props
       ,props.doc
       ,{
            label: "Имя файла"
           ,name: "fileName"
           ,value: props.uploads.fileName
           ,validators: []
           ,onChange: e => props.onChange( e )
        }
    );
}

const UploadsForm = ( props ) => {

    const GroupSelect = getUserGroupSelect( props );
    const DocSelect = getUserDocSelect( props, false )[0];
    const StatusSelect = getProcessingStatusList( props );
    const FileNameInput = getFileNameInput( props );
    const docId = props.doc.docId;

    return (
        <div className={styles.UploadsForm}>
            <Card className={styles.xxGridActionPanel}>
                <CardContent className='Card'>
                    <ValidatorForm
                        id="uploadsForm"
                        onKeyPress={props.onFormKeyPress}
                        onSubmit={props.onFormSubmit}
                    >
                    <div style={{ display: "flex" }}>
                        <div style={{ margin: "15px", width: "100%" }}>
                            { GroupSelect }
                            { DocSelect }
                            { StatusSelect }
                            { FileNameInput }
                        </div>
                        <div style={{ margin: "-1.75px", marginRight: "15px" }}>
                            <UploadsFilter
                                props={props}
                                uploads={props.uploads}
                            />
                        </div>
                    </div>
                    <Legend/>
                    <div>
                        <UploadsActions
                            props={props}
                            uploads={props.uploads}
                        />
                    </div>
                    {!docId ? '' :
                        <UploadsTable
                            uploads={props.uploads.uploads[docId]}
                            props={props}
                            onUpdateUploads={props.onUpdateUploads}
                        />
                    }
                    </ValidatorForm>
                </CardContent>
            </Card>
        </div>
    );
}

export default UploadsForm
