// =================================
// служебные функции для optionsList

// получить optionsList из компоненты
// по названию компоненты
/*export function getOptionsListByComponentType( propsDoc, componentType ) {
 
    const component = propsDoc.docParts[ componentType ];
    if( component === undefined ) return undefined;
    
    let optionsList = component.optionsList;
    if( optionsList === undefined ) return undefined;
    
    if( !Array.isArray( optionsList ) ) optionsList = propsDoc[ optionsList ];
    if( !Array.isArray( optionsList ) ) return undefined;
    
    return optionsList;
}*/

// получить optionsList из компоненты
export function getOptionsListByItem( propsDoc, item ) {
 
    let optionsList = item.optionsList;
    if( optionsList === undefined ) return undefined;
    
    if( !Array.isArray( optionsList ) ) optionsList = propsDoc[ optionsList ];
    if( !Array.isArray( optionsList ) ) return undefined;
    
    return optionsList;
}

// получить поле из текущего шаблона
// по имени поля
/*function getTemplateFiled( propsDoc, fieldName ) {
    const docId = propsDoc.docId;
    
    const template = propsDoc.docTemplates[ docId ];
    if( !Array.isArray( template ) ) return undefined;
    
    const field = template.find( i => i.name === fieldName );
    return field;
}*/

// получить optionName из шаблона
// по имени поля
/*export function getOptionName( propsDoc, fieldName ) {
    const field = getTemplateFiled( propsDoc, fieldName )
    const optionName = field ? field.optionName : undefined;
    return optionName ? optionName : "name";
}*/


// ====================================================
// функции для Документа и Админки с учётом optionsList

/* TODO стал не нужен
// готовим defaultValue для поля на форме Документ,
// преобразовать defaultValue
// в соответствии с optionName = "name" / "fullName" или ещё что-то другое
export function getOptionsListDefaultValue( propsDoc, componentType, optionName, defaultValue ) {

    const optionsList = getOptionsListByComponentType( propsDoc, componentType );
    if( !Array.isArray( optionsList ) ) return defaultValue;

    const listItem = optionsList.find( i => i.name === defaultValue );
    const defaultOptionValue = listItem ? listItem[ optionName ] : undefined;
    
    if( defaultOptionValue !== undefined ) return defaultOptionValue;
    return defaultValue;
}*/


/* TODO стал не нужен для составных полей Yandex и т.п.
// готовим list для списочного поля на форме Документ
// при наличии optionsList
export function getDocItemList( propsDoc, item ) {
 
    // optionsList - полный список значений, доступных для отбора в список list,
    // может быть задан в свойствах компонента,
    // массив [...] или имя массива в props.doc

    const list = item.list; // список из шаблона

    const optionsList = getOptionsListByComponentType( propsDoc, item.type );
    if( !Array.isArray( optionsList ) ) return list;

    const optionName = item.optionName === undefined ? "name" : item.optionName;

    // если list не задан,
    // заполнить элементами из optionsList с признаком isDefault
    if( !Array.isArray( list ) ) {
        return optionsList.filter( i => String( i.isDefault ) === '1' ).map( i => i[ optionName ] );
    }

    // список optionsList может содержать записи из нескольких полей, например, "name", "fullName",
    // в шаблоне хранится list со значениями "name"
    if( optionName === "name" ) return list;
    
    // если нужно "fullName" или ещё что-то другое
    return optionsList.filter( i => list.includes( i.name ) ).map( i => i[ optionName ] );
}*/


// готовим list для списочного поля на форме Документ,
// optionsList задан в параметрах
export function getDocItemListByOptionsList( optionsList, list, indexOptionName = "name", optionName = "name" ) {

    if( !Array.isArray( optionsList ) ) return list; // list - список из шаблона

    // список optionsList может содержать записи из нескольких полей, например, "name", "fullName",
    // в шаблоне хранится list со значениями "name"
    // if( optionName === "name" ) return list;
    
    // но мы не возвращаем его сразу,
    // во-первых optionsList отсортирован, а list - нет,
    // во-вторых list - это white list, но его значений может не оказаться в актуальном справочнике,
    // нужно отфильтровать optionsList

    // если нужно "fullName" или ещё что-то другое
    if( Array.isArray( list ) && list.length > 0 ) {
        list = optionsList.filter( i => list.includes( i[ indexOptionName ] ) ).map( i => i[ optionName ] );
    }
    
    // заполнить элементами из optionsList с признаком isDefault
    if( !Array.isArray( list ) || list.length === 0 ) {
        return optionsList.filter( i => String( i.isDefault ) === '1' ).map( i => i[ optionName ] );
    }
    
    return list;
}


// готовим list для редактора списка в Админке -
// список из шаблона или из optionsList
export function getAdminItemList( list, optionsList, optionName = "name" ) {
 
    // удалить значения, которых нет в optionsList
    if( Array.isArray( list ) && Array.isArray( optionsList ) ) {
        const values = optionsList.map( i => i[ optionName ] );
        list = list.filter( i => values.includes( i ) ); // обычный список строк [ 'value', ... ]
    }
    
    // если список list ещё не определён, заполнить значениями по умолчанию
    if( ( !Array.isArray( list ) || list.length === 0 ) && Array.isArray( optionsList ) ) {
        list = optionsList.filter( i => String( i.isDefault ) === '1' ).map( i => i[ optionName ] ); // в шаблоне и в Админке всегда "краткое имя"
    }
    
    if( !Array.isArray( list ) ) list = [''];
    list = list.map( i => i === undefined || i.id === undefined ? i : `${i.id} (${i.name})` );
    return list;
}


// ================================================
// получить значения зависимых полей из optionsList

// получить optionsList из компоненты
// по имени поля
/*function getOptionsList( propsDoc, fieldName ) {
    const field = getTemplateFiled( propsDoc, fieldName );
    if( field === undefined ) return undefined;
     
    const componentType = field.type;
    return getOptionsListByComponentType( propsDoc, componentType );
}*/

// получить одну запись record из optionsList
// по имени и значению поля записи
export function getOptionsListRecord( optionsList, searchAttr, searchValue ) {
    if( !Array.isArray( optionsList ) ) return undefined;
    
    return optionsList.find( item => item[ searchAttr ] === searchValue );
}

export function getOptionsListRecords( optionsList, searchAttr, searchValue ) {
    if( !Array.isArray( optionsList ) ) return undefined;
    
    return optionsList.filter( item => item[ searchAttr ] === searchValue );
}

// получить одну запись record из optionsList
// по имени поля шаблона,
// имени и значению поля записи
/*function getOptionsListRecordByFieldName( propsDoc, fieldName, searchAttr, searchValue ) {
    const optionsList = getOptionsList( propsDoc, fieldName );
    if( !Array.isArray( optionsList ) ) return undefined;

    return optionsList.find( item => item[ searchAttr ] === searchValue );
}*/

// получить одну запись record из optionsList
// по типу компоненты шаблона,
// имени и значению поля записи
/*export function getOptionsListRecordByComponentType( propsDoc, componentType, searchAttr, searchValue ) {
    const optionsList = getOptionsListByComponentType( propsDoc, componentType );
    return getOptionsListRecord( optionsList, searchAttr, searchValue );
}*/

// например, в компоненте "Компания группы Яндекс"
// есть optionsList = [ records ],
// record состоит из значений "name", "fullName", "inn", "address", "isDefault",
// в поле шаблона есть optionName = "name" / "fullName" - отображать краткие или полные названия юрлиц

// В общем случае
// по имени поля fieldName можно найти optionsList,
// в нём найти record по searchAttr и searchValue,
// затем из record выбрать значение по имени propName

/*export function getOptionsListProp( propsDoc, fieldName, searchAttr, searchValue, propName ) {
    const record = getOptionsListRecordByFieldName( propsDoc, fieldName, searchAttr, searchValue );
    return record ? record[ propName ] : '';
}*/

export function getOptionsRecordProp( record, propName ) {
    return record ? record[ propName ] : '';
}

// добавить поля _dd, _mm, _yyyy к полям date
export function dateParts( record ) {
    for( let k in record ) {
        // считаем, что достаточно проверки тега на присутствие подстроки "date",
        // потому что теги сложных компонентов зашиты в коде
        // + исключение для списка subpoena_receipt_date_list
        if( !k.includes( "date" ) || k.includes( "list" ) ) continue;
        const value = record[ k ];
        const [ dd, mm, yyyy ] = value ? value.split(/\.| /) : [ "", "", "" ];
        record[ k + "_dd" ] = dd;
        record[ k + "_mm" ] = mm;
        record[ k + "_yyyy" ] = yyyy;
    }
}

export function copyProps( target, source, tail ) {
    // Object.assign( target, source );
    // Object.keys( source ).forEach( key => {});
    for( let k in source ) {
        target[ k + tail ] = source[ k ];
    }
}

