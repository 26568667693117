import React from 'react'
import DateInput from '../DocParts/DateInput';
import Input from '../DocParts/Input';
import Button from '../AdminParts/Button';


const HistoryFilter = ( { props, history } ) => {
    return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: "16px" }}>
        
            <div>
            <DateInput
                label="Дата с"
                name="dateFrom"
                value={ history.dateFrom }
                onChange={ date => { props.onNameValueChange( "dateFrom", date ); props.onUpdateHistory(); } }
                invalidDateMessage=''
                maxDateMessage=''
                minDateMessage=''
            />
            </div>
            
            <div style={{ marginLeft: "7px" }}>
            <DateInput
                label="Дата по"
                name="dateTo"
                value={ history.dateTo }
                onChange={ date => { props.onNameValueChange( "dateTo", date ); props.onUpdateHistory(); } }
                invalidDateMessage=''
                maxDateMessage=''
                minDateMessage=''
            />
            </div>
            
            <div>
            <Input
                label="Количество строк"
                name="limit"
                value={ history.limit }
                onChange={ e => { props.onChange( e ); props.onUpdateHistory(); } }
            />
            </div>
            
            <div style={{ marginLeft: "7px", marginTop: "7px" }}>
            <Button
                name="update"
                onClick={ props.onUpdateHistory }
                label="Обновить"
            />
            </div>
            
        </div>
    )
}

export default HistoryFilter;
