import React from 'react';
import styles from "./UploadsTable.module.css"
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import DescriptionRounded from "@material-ui/icons/DescriptionRounded";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {COLOR_BLUE, COLOR_GREEN, COLOR_LIGHT_GREY, COLOR_RED, COLOR_YELLOW} from "../../constants";
import ClearIcon from "@material-ui/icons/Clear";
import ProgressCircle from "../../containers/ProgressCircle";
import BlockRoundedIcon from "@material-ui/icons/BlockRounded";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";

const useStyles = makeStyles({
    div: {
        padding: "20px"
       ,fontSize: "16px"
    }
});

export default function SimplePopover() {
    const classes = useStyles();
    const [ anchorEl, setAnchorEl ] = React.useState( null );

    const handleClick = (event) => {
        setAnchorEl( event.currentTarget );
    };

    const handleClose = () => {
        setAnchorEl( null );
    };

    const open = Boolean( anchorEl );
    const id = open ? 'uploads-help' : undefined;

    return (
        <>
            <IconButton
                color="primary"
                style={{position: "absolute", top: -5, right: -5}}
                onClick={handleClick}
            >
                <HelpOutlineRounded fontSize="large" className={styles.xxHelp}/>
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom'
                    , horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top'
                    , horizontal: 'center'
                }}
            >
                <div className={classes.div}>
                    <table>
                        <tbody>
                        <tr>
                            <td style={{padding: "10px"}}><DescriptionRounded fontSize="default" className={styles.doc_icon}/></td>
                            <td>Файл с результатами выполнения обработки данных.
                                <br/>Содержит исходные данные и столбец "Сообщения", в котором фиксируются ошибки
                                выполнения
                                <br/>или указана ссылка на сформированный тикет Трекера.
                            </td>
                        </tr>
                        <tr style={{backgroundColor: "white"}}>
                            <td><IconButton color="inherit" style={{width: 40, height: 40, color: COLOR_BLUE}}>
                                <PlayArrowIcon fontSize="default"/>
                            </IconButton>
                            </td>
                            <td>Запустить обработку файла</td>
                        </tr>
                        <tr>
                            <td><IconButton color="inherit" style={{width: 40, height: 40, color: COLOR_BLUE}}>
                                <ClearIcon fontSize="default"/>
                            </IconButton>
                            </td>
                            <td>Отменить. Статус загрузки будет изменен на "Отменено". Обработать файл невозможно.</td>
                        </tr>
                        <tr style={{backgroundColor: "white"}}>
                            <td><ProgressCircle statusLabel ="В работе"/>
                            </td>
                            <td>В работе. Обработка файла в процессе.</td>
                        </tr>
                        <tr style={{backgroundColor: "white"}}>
                            <td><BlockRoundedIcon style={{width: 40, height: 40, color: COLOR_LIGHT_GREY}}/>
                            </td>
                            <td>Отменено</td>
                        </tr>
                        <tr style={{backgroundColor: "white"}}>
                            <td><ErrorOutlineRoundedIcon style={{width: 40, height: 40, color: COLOR_YELLOW}}/>
                            </td>
                            <td>Завершено с предупреждением. Обработка файла завершена, часть строк обработана с
                                ошибками.
                                <br/>Ошибки можно просмотреть в поле "Сообщения", скачав файл с результатами обработки.
                            </td>
                        </tr>
                        <tr style={{backgroundColor: "white"}}>
                            <td><CheckCircleOutlineRoundedIcon style={{width: 40, height: 40, color: COLOR_GREEN}}/>
                            </td>
                            <td>Успешно завершено. Все строки файла успешно обработаны.
                                <br/>Просмотреть сформированный тикет можно перейдя по ссылке в поле "Сообщения",
                                <br/>скачав файл с результатами обработки.
                            </td>
                        </tr>
                        <tr style={{backgroundColor: "white"}}>
                            <td><ErrorOutlineRoundedIcon style={{width: 40, height: 40, color: COLOR_RED}}/>
                            </td>
                            <td>Завершено с ошибками. Обработка всех строк завершилась с ошибками.
                                <br/>Ошибки можно просмотреть в поле "Сообщения", скачав файл с результатами обработки.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Popover>
        </>
    );
}
