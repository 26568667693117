import axios from 'axios'
import {
// actions
    ADD
   ,DELETE
   ,SET_ID
// reducer names
   ,ROLE
// properties
   ,APP_URL
   ,STAFF_ALL_LIST_ITEM
} from '../constants'
import {
    get_common
   ,post_common
   ,addListValues
   ,setListById
   ,setPropValue
} from '../actions/utils'
import {
    caption
} from './lang';

export const addRole = ( id, name, value ) => (
  {
    type: ROLE + ADD,
    payload: {
        id
       ,name
       ,value
    }
  }
);

export const deleteRole = ( id, name, roleId ) => (
  {
    type: ROLE + DELETE,
    payload: {
        id
       ,name
       ,roleId
    }
  }
);

export const setRoleId = ( id, name, oldId, newId ) => (
  {
    type: ROLE + SET_ID,
    payload: {
        id
       ,name
       ,oldId
       ,newId
    }
  }
);

let staffCancelRequest;
let staffLastParam;
export function getStaff( dispatch, listName, value ) {
 
    value = ( value === undefined || value === null ) ? '' : value;
    value = value.replace(/\r?\n|\r/gm, ""); // чистим перевод строки
    //value = value.replace(/[^\x20-\x7E]/gmi, ""); // или так
    
    if( value === '' || value === staffLastParam ) return;
    staffLastParam = value;
    
    if( staffCancelRequest ) {
        staffCancelRequest.cancel();
    }
    staffCancelRequest = axios.CancelToken.source();

    
    var counter = 0;

    const clearList = () => {
        if( ++counter === 1 ) {
            // пришла первая порция данных, нужно убрать старые из списка, оставить только "Все"
            // Админы шаблона = Все - убрали, лишняя возможность
            dispatch( setPropValue( ROLE, listName, listName === "staffTemplateAdminList" ? [] : [ STAFF_ALL_LIST_ITEM ] ) );
        }
    }

    get_common(
        APP_URL + '/api/staff/people' // per_all_people_f - быстро, но не все
       ,dispatch
       ,caption.empListError + ': '
       ,data => {
            
            // новая версия - ответ StaffAPI прошёл через JSON parser
            if( !data ) return;
            const list = data.map( i => ({
                id:   i.login
               ,name: i.name + ", " + i.login
               ,tooltip: i.name
               ,type: "PERSON"
            }) );
            
            clearList();
            dispatch( addListValues( ROLE, listName, list ) );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: staffCancelRequest.token, }
    );
    
    get_common(
        APP_URL + '/api/staff/persons' // StaffAPI - все, но медленно
       ,dispatch
       ,caption.empListError + ': '
       ,data => {
            /* старая версия, ответ сразу из StaffAPI
            if( !data.result ) return;
            const list = data.result.map( i => ({
                id:   i.login
               ,name: i.name.first.ru + " " + i.name.last.ru + ", " + i.login
               ,tooltip: i.name.first.ru + " " + i.name.last.ru
               ,type: "PERSON"
            }) );*/
            
            // новая версия - ответ StaffAPI прошёл через JSON parser
            if( !data ) return;
            const list = data.map( i => ({
                id:   i.login
               ,name: i.name + ", " + i.login
               ,tooltip: i.name
               ,type: "PERSON"
            }) );
            
            clearList();
            dispatch( addListValues( ROLE, listName, list ) );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: staffCancelRequest.token, }
    );
    
    get_common(
        APP_URL + '/api/staff/groups'
       ,dispatch
       ,caption.depListError + ': '
       ,data => {
            if( !data.result ) return;
            const list = data.result.map( i => ({
                id:   i.url
               ,name: i.name // + ", " + i.url
               ,tooltip: i.name
               ,type: "GROUP"
            }) );
            clearList();
            dispatch( addListValues( ROLE, listName, list ) );
        }
       ,f=>f
       ,null
       ,{ params: { value }, cancelToken: staffCancelRequest.token, }
    );
    
}

export function addRoleDB( dispatch, id, listName, role ) {

    const formData = new FormData();
    formData.append('itemType',   role.itemType);
    formData.append('itemId',     role.itemId);
    formData.append('accessType', role.accessType);
    formData.append('userType',   role.userType);
    formData.append('userId',     role.userId);
    formData.append('userName',   role.userName);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/role/add'
       ,formData
       ,config
       ,dispatch
       ,caption.addRoleError + ': '
       ,data => {
            const roleId = data;
            if( !Number.isInteger( roleId ) ) return;
            dispatch( setRoleId( id, listName, role.roleId, roleId ) ); // заменить временный id на постоянный из базы
        }
    );
}

export function deleteRoleDB( dispatch, id, name, roleId, itemType, itemId ) {

    if( !Number.isInteger( roleId ) ) return;
    // не удаляю, пока роль добавляется в базу
    if( roleId < 1 ) { /* dispatch( deleteRole( id, name, roleId ) ); */ return; }
    const formData = new FormData();
    formData.append('roleId',   roleId);
    formData.append('itemType', itemType);
    formData.append('itemId',   itemId);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/role/delete'
       ,formData
       ,config
       ,dispatch
       ,caption.delRoleError + ': '
       ,data => {
            if( data !== 'OK' ) return;
            dispatch( deleteRole( id, name, roleId ) );
        }
    );
    
}

export function loadRoleListDB( dispatch, listName, id, itemType, accessType ) {

    const config = {
        params: {
            itemType
           ,itemId: id
           ,accessType
           ,userType: ''
           ,userId: ''
        }
    }

    get_common(
        APP_URL + '/api/role'
       ,dispatch
       ,caption.roleListError + ': '
       ,data => { dispatch( setListById( ROLE, listName, id, data ) ); }
       ,f=>f
       ,null
       ,config
    );
}
