import React, { Component } from 'react';
import { connect } from 'react-redux';
import VUser from './../components/User/User';
import {
    get_common
   ,setPropValue
} from '../redux/actions/utils'
import {
   // actions
    SET_ALL_LOGINS
   // properties
   ,APP_URL
   ,LOGIN
   // reducer names
   ,DOC
   ,USER
} from '../redux/constants'

let mounted = false;

class User extends Component {

    componentDidMount() {
        !mounted && this.props.getUser();
        mounted = true;
    };

    render() {
        return (
            <VUser
                {...this.props}
            />
        );
    };
};

const mapStateToProps = ({user}) => {
    return {
        user
    };
};

const mapDispatchToProps = dispatch => ({
    getUser: () => {
        get_common(
            APP_URL + '/api/user'
           ,dispatch
           ,'Ошибка при получении информации о пользователе: '
           ,data => {
                const login = data.login ? data.login.toLowerCase() : '';
                dispatch( setPropValue( USER, 'roles', data.roles ) );
                dispatch( setPropValue( USER, 'isSuper', data.roles.includes( "ROLE_cdoc_admin" ) ) );
                dispatch( setPropValue( USER, 'isHrSuper', data.roles.includes( "ROLE_cdoc_hr" ) ) );
                dispatch( setPropValue( USER, 'isInitiator', data.roles.includes( "ROLE_cdoc_initiator" ) ) );
                dispatch( setPropValue( USER, 'isExternal', data.roles.includes( "ROLE_cdoc_external" ) ) );
                dispatch( setPropValue( USER, 'login', data.login ) );
                dispatch( setPropValue( DOC, LOGIN, login ) );
                dispatch( { type: DOC + SET_ALL_LOGINS } );
            }
        );
    }
})

export default connect( mapStateToProps, mapDispatchToProps )( User );
