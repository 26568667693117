import {
    // actions
    CLEAR_UUIDS
   ,SET_STATE_PROP_VALUE
   ,SET_STATE_PROP_MAP
   ,SET_TEMPLATE_FORMAT
    // reducer names
   ,DOC
   ,FORM_TEMPLATE
} from '../constants'
import {
    getCsrfToken
   ,post_common
   ,setPropValue
} from '../actions/utils'
import {
    errorSnackbar
} from '../actions/snackbar';
import {
    caption
} from './lang';
import {
    previewLastField
} from '../actions/preview';


export const setDocStatePropValue = ( name, value ) => {
 
    // Preview, скролл на редактируемое поле
    previewLastField( name );
    
    return (
      {
        type: DOC + SET_STATE_PROP_VALUE,
        payload: {
            name
           ,value
        }
      }
    );
};


export const setDocStatePropMap = ( map ) => {
    
    // TODO можно выбрать не одно поле, а полный список
    // см. gLastField в cdoc\front\public\preview\preview.js
    
    // Preview, скролл на редактируемое поле
    const keys = Object.keys( map );
    const name = keys ? keys[0] : null;
    if( name !== null ) previewLastField( name );
    
    return (
      {
        type: DOC + SET_STATE_PROP_MAP,
        payload: {
            map
        }
      }
    );
};


export const clearUuids = () => (
  {
    type: DOC + CLEAR_UUIDS
  }
);

export const setTemplateFormat = ( value ) => (
  {
    type: FORM_TEMPLATE + SET_TEMPLATE_FORMAT,
    payload: {
        value
    }
  }
);


// пустые checkboxes установить в false
// Стандартное поведение браузеров - не устанавливать значение checkbox, пока пользователь не кликнул:
// https://stackoverflow.com/questions/4228658/what-values-for-checked-and-selected-are-false
// There are no values that will cause the checkbox to be unchecked.
// If the checked attribute exists, the checkbox will be checked regardless of what value you set it to.
// Это очень странно и неудобно, получается три состояния - true, false и пусто.
// Незаполненный checkbox не попадает в результаты формы,
// и в шаблоне нужно учитывать пустой checkbox.
// Но лучше заполнить принудительно пусто -> false
// либо скриптом по onFormSubmit, как здесь, либо по componentDidMount на Checkbox.
export function fillEmptyCheckboxes( dispatch, formData ) {

    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const docId = propsDoc.docId;
    const template = propsDoc.docTemplates[ docId ];
    const state = propsDoc.docStates[ docId ];

    template.filter( i => i.type === "Checkbox" ).forEach(
        i => formData.set( i.name, state[ i.name ] )
    );

};


export function getSubtemplateFiles( dispatch, formData ) {

    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const docId = propsDoc.docId;
    const template = propsDoc.docTemplates[ docId ];
    const state = propsDoc.docStates[ docId ];

    template.filter( i => i.type === "Subtemplate" && state[ i.name ] ).forEach(
        i => formData.set( i.name, state[ i.name ] )
    );

};


export function getBarcodes( dispatch, formData ) {

    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const docId = propsDoc.docId;
    const template = propsDoc.docTemplates[ docId ];
    const state = propsDoc.docStates[ docId ];

    template.filter( i => i.type === "Barcode" && state[ i.name ] ).forEach(
        i => formData.set( i.name, state[ i.name ] )
    );

};


// Компонент Таблица
// перед отправкой формы
// вместо значений полей Input
// подставить JSON массив
export function getTables( dispatch, formData ) {

    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    const docId = propsDoc.docId;
    const template = propsDoc.docTemplates[ docId ];
    const state = propsDoc.docStates[ docId ];

    template.filter( i => i.type === "Table" && Array.isArray( state[ i.name ] ) ).forEach( i => {
     
        // удалить поля table$r$c по маске
        // TODO не все удаляются почему-то, но не критично
        for( const pair of formData.entries() )
            if( pair[0].startsWith( i.name + '$' ) )
                formData.delete( pair[0] );
        
        return formData.set( i.name, JSON.stringify( state[ i.name ] ) );
    });

};


export function downloadFile( data, filename, format, responseFilename ) {
 
    var signature = data.slice( 0, 6 );
    if( signature instanceof ArrayBuffer )
        signature = String.fromCharCode.apply( null, new Uint8Array( signature ) );

    if( format === "xls" );
    else if( format === "json" );
    else if( signature === "{\\rtf1" ) format = "rtf";
    else if( signature === "%PDF-1" ) format = "pdf";
    else if( signature.startsWith("PK") ) format = "docx";
    else if( signature.startsWith("zip") ) {
        format = "zip"; // zip дописали в backend-е, чтобы отличить от docx
        data = data.slice(3); // теперь нужно отрезать zip
    }
    else if( signature.startsWith("7z") ) format = "7z";
    else if( signature === "<?xml " ) format = "xml";
    //else if( signature.charCodeAt(0) === 0xEF ) format = "xml"; // будем считать, что это XML с BOM UTF-8
    else if( signature.slice(3) === "<?x" ) format = "xml"; // будем считать, что это XML с BOM UTF-8
    else format = "csv";
    
    let fullName = responseFilename === undefined || responseFilename === null || responseFilename === "" ?
        filename + "." + format : responseFilename;

    let blobData = new Blob([data], {type: "application/octet-binary" });

    if( window.navigator && window.navigator.msSaveOrOpenBlob ) {
        // for IE
        window.navigator.msSaveOrOpenBlob( blobData, fullName );
    } else {
        // For others
        let element = document.createElement( "a" );
        document.body.appendChild( element );
        element.setAttribute( "href", window.URL.createObjectURL( blobData ) );
        element.setAttribute( "download", fullName );
        element.style.display = "";
        element.click();
        document.body.removeChild( element );
    }
    
};


// FormData -> Object
// для передачи FormData родителю фрейма,
// повторяющиеся атрибуты сохранить в списки
function formDataToObj( formData ) {

    // только уникальные атрибуты, без повторов
    //const obj = Object.fromEntries( [ ...data ] );

    // с повторяющимися атрибутами
    var obj = {};
    formData.forEach((value, key) => {
        // Reflect.has in favor of: obj.hasOwnProperty(key)
        if(!Reflect.has(obj, key)){
            obj[key] = value;
            return;
        }
        if(!Array.isArray(obj[key])){
            obj[key] = [obj[key]];    
        }
        obj[key].push(value);
    });
    //var json = JSON.stringify(obj);
    return obj;
}


// Object -> FormData
// повторяющиеся атрибуты хранятся в списках
/* не удалять
function objToFormData( obj ) {

    const formData = new FormData();
    
    Object.keys( obj ).forEach( key => {
        const value = obj[ key ];
     
        if( Array.isArray( value ) ){
            value.forEach( item => {
                formData.append( key, item );
            });
            return;
        }
        
        formData.append( key, value );
    });

    return formData;
}*/


let isRequestInProgress = false; // prevent multiple requests
export function requestFile( url, defaultFileName, format, dispatch, data = null ) {

    if( isRequestInProgress ) {
        return;
    }
  
    // передаём uuid пользователя внешних форм
    const propsUser = dispatch( ( dispatch, getState ) => getState().user );
    const extUserId = propsUser.extUserId;
    const isPassiveIframe = propsUser.isPassiveIframe;
    
    if( data ) data.append( 'extUserId', extUserId );
    
    let filename = defaultFileName;
    if( !filename || filename === "doc" ) filename = data.get( "fileName" ); // from the hidden input in DocForm.jsx

    // cdoc встраивают в iframe
    // и хотят получить параметры запроса
    if( isPassiveIframe ) {

        // обновить csrf-token,
        // отправить параметры запроса родителю фрейма и выйти

        const obj = formDataToObj( data ); // упаковка FormData
        //const postData = objToFormData( obj ); // распаковка FormData - нужно вызвать на стороне родителя фрейма
        const host = window.location.origin;
        getCsrfToken( dispatch, () =>
            window.top.postMessage( { type: "data", host, url, data: obj, "x-csrf-token": window.csrf_token, filename, format }, '*' )
        );

        //return;
        // или другй вариант, потом выберем один -
        // выполнить запрос и отправить полученный файл родителю фрейма
    }
    

    const config = data ? { responseType: 'arraybuffer', headers: { 'content-type': 'multipart/form-data' } } : {};

    isRequestInProgress = true;
    post_common(
        url
       ,data
       ,config
       ,dispatch
       ,caption.templateError + " "
       ,( response, responseFilename ) => {
            isRequestInProgress = false;
            const start = String.fromCharCode.apply( null, new Uint8Array( response.slice(0, 7) ) );
            if( start === 'EXPIRED' ) {
                dispatch( setPropValue( DOC, 'expiredUrl', true ) );
                errorSnackbar( 'EXPIRED' );
            //} else if( start.startsWith ( 'ERROR' ) ) {
            //    errorSnackbarLong( String.fromCharCode.apply( null, new Uint8Array( response ) ) );
            } else {
                if( isPassiveIframe )
                    // отправить полученный файл родителю фрейма
                    window.top.postMessage( { type: "file", response, filename, format }, '*' );
                else
                    downloadFile( response, filename, format, responseFilename );
            }
        }
       ,() => { isRequestInProgress = false; } // better use axios finally()
    );

};
