import React from 'react';
import styles from "./Form.module.css"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
//import Tooltip from '@material-ui/core/Tooltip';
//import IconButton from '@material-ui/core/IconButton';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Grow from '@material-ui/core/Grow';
import {
    setPropValue
} from '../../redux/actions/utils'
import {
    ITEM_EDITOR
} from '../../redux/constants'
import {
    isLangEn
} from '../../redux/actions/lang';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

// Карандаш - редактор атрибутов раздела edit из DocParts.js


function modifyItem( item, props ) {

    if( item.useListFromTemplate ) item.list = props.item.list;

    props.onNameValueChange = ( name, value ) => props.dispatch( setPropValue( ITEM_EDITOR, name, value ) );
    
    // передаём список всех полей на форме, чтобы выбрать поле, управляющее видимостью
    if( item.name === "visibilityParentName" ) {
        item.list = props.itemList;
        props.onNameValueChange = ( name, value ) => { props.dispatch( setPropValue( ITEM_EDITOR, name, value ) ); props.onLoadItemList( value, props ); };
    }
    // список из выбранного поля, если поле со списком
    if( item.name === "visibilityParentValue" ) {
        const list = props.visibilityParentList;
        if( Array.isArray( list ) ) {
            item.type = "Select"; // InputWithSelect не справится со списком yesNoList для Checkbox
            // item.isLang = true; // так будет работать, если все значения - строки, например "true", а не true, но для Checkbox нужны boolean
            item.list = list;
        }
    }
}

// build - отрисовать поля Админки,
// группировать поля с признаком isRowGroup в одну строку
function buildDynForm( template, docParts, props ) {

    const res = [];
    let idx = 0;

    template.forEach( ( item, templateIdx ) => {
     
        if( !docParts[ item.type ] ) return;

        const newItem = { ...item };
        const newProps = { ...props };

        modifyItem( newItem, newProps );
        
        const formField = docParts[ newItem.type ].build( // параметры build( props, state, extra, styles )
            newProps/*{
                ...props
               //,onChange // для компонентов с event-ами, он уже есть в props
               ,onNameValueChange // для компонентов без event-ов
            }*/
           ,props
           ,newItem
           ,styles
        );
        
        // Подсказка для поля
        const tooltip = '';
        
        // React требует keys.
        // Скрытые поля не оборачиваем в div.
        
        const formKeyField = formField ? <div key={ templateIdx } style={{ width: "100%" }} >{ tooltip }{ formField }</div> : '';
        
        const prevItem = templateIdx > 0 ? template[ templateIdx - 1 ] : null;
        if( prevItem && prevItem.isRowGroup && idx > 0 ) res[ idx - 1 ].push( formKeyField );
        else { res.push( [ formKeyField ] ); ++idx; }

    });

    return res;
}


const ItemEditor = ( props ) => {

    if( !props.open ) return '';

    const state = props.docTemplate[ props.idx ];
    const docPart = props.docParts[ state.type ];

    const onOk = props.onOk === undefined ? props.onCancel : () => { props.onCancel(); props.onOk( props.idx, props, docPart.edit ); }

    
    let DynForm = null;
    if( props.docParts && docPart.edit ) {
     
        const admin = [];
        
        // lang - добавляем перевод для Подсказки,
        // если перевод не задан - в Админке будет пустое значение,
        // а на форме Документ - значение для ru
        docPart.edit.forEach( i => {
            
            // добавить поле с переводом
            if( isLangEn && i.name === "tooltip" ) {
                let item = { ...i };
                item.name += '_en';
                item.label += ' [En]';
                admin.push( i ); // или admin.push( { ...i, isRowGroup: true } ); чтобы сгруппировать в строку
                admin.push( item );
            } else admin.push( i );
            
        });

        
        DynForm = buildDynForm( admin, props.docParts, props );
    }
    

    const renderItem = (item, key) => <div key={key} className='table__row' >{ item }</div>;
    
    return (
        <Dialog
            open={ true }
            TransitionComponent={ Transition }
            onBackdropClick={ props.onCancel }
            onEscapeKeyDown={ props.onCancel }
        >
        
            <ValidatorForm
                id="itemEditorForm"
                onSubmit={ onOk }
                className={ styles.xxForm }
            >

                {/*<DialogTitle>Поле</DialogTitle>*/}
                
                <DialogContent>
                
                    {/*<Tooltip title="Закрыть" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: 0, right: 5 }}
                        onClick={ props.onCancel }
                    >
                        <ClearRounded fontSize="large" className={ styles.xxDelete } />
                    </IconButton>
                    </Tooltip>*/}
                
                    <DialogContentText>{ props.label }</DialogContentText>
                    
                    { DynForm ? DynForm.map( renderItem ) : '' }

                </DialogContent>
                <DialogActions>

                    <Button
                        onClick={ onOk }
                        color="secondary"
                        variant="outlined"
                        size="small"
                        autoFocus
                    > Ок </Button>
                    
                    { props.onOk === undefined ? '' :
                        <Button onClick={ props.onCancel } color="secondary" variant="outlined" size="small" > Отмена </Button>
                    }
                    
                </DialogActions>
            
            </ValidatorForm>
            
        </Dialog>
    );
};

export default ItemEditor;
