import React from 'react'
import './AdminForm.css'
import styles from "./Form.module.css"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ValidatorForm } from 'react-material-ui-form-validator';
import Movable from '../Movable/Movable';

import MButton from '@material-ui/core/Button';
import Button from '../AdminParts/Button';
import GroupEditor from '../../containers/GroupEditor';
import TemplateEditor from '../../containers/TemplateEditor';
import ItemEditor from '../../containers/ItemEditor';
import ListEditor from '../../containers/ListEditor';
import ExtEditor from '../../containers/ExtEditor';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddRounded from '@material-ui/icons/AddRounded';
import ArrowRightAltRounded from '@material-ui/icons/ArrowRightAltRounded';
import ClearRounded from '@material-ui/icons/ClearRounded';
import EditRounded from '@material-ui/icons/EditRounded';
import SaveOutlined from '@material-ui/icons/SaveOutlined';
import RefreshRounded from '@material-ui/icons/RefreshRounded';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Send from '@material-ui/icons/Send';
//import ShareRounded from '@material-ui/icons/ShareRounded';
import Badge from '@material-ui/core/Badge';

import {
    getAdminDocSelect
   ,getAdminGroupSelect
   ,urlCopy
} from '../../redux/actions/admin'
import BankListsEditor from "../../containers/BankListsEditor";


const AdminForm = ( props ) => {
    
    const Components = {
        Button: extra => Button({
            ...extra
        })
    };
    
    const GroupSelect = getAdminGroupSelect( props );
    const DocSelect = getAdminDocSelect( props, true );

    const groupId = props.doc.groupId;
    const docId = props.doc.docId;
    const groupIdAdmin = props.doc.groupAdminList.findIndex( i => i.id === groupId );
    const docIdAdmin = props.docIdAdmin;
    const isChanged = docId > 0 && docIdAdmin > -1 ? props.doc.docAdminList[ docIdAdmin ].isChanged : false;
    
    const copy = props.session.copy; // Скопированные поля шаблона
    const copyLength = copy ? copy.length : 0;
    
    // Скопированные поля шаблона
    const copyIcon = copyLength ?
        <Tooltip title="Очистить буфер обмена" arrow>
        <IconButton
            color="primary"
            style={{ position: "fixed", top: -4, right: 360, zIndex: 1250 }}
            onClick={ props.onClearSelection }
        >
            <Badge color="error" style={{ top: 1 }} badgeContent={ copyLength } >
                <PlaylistAddIcon id="copiedFields" fontSize="large" className={ styles.xxUpdate } />
            </Badge>
        </IconButton>
        </Tooltip>
        :
        // нам нужны координаты иконки для анимации копирования поля,
        // отрисуем прозрачную иконку
        <PlaylistAddIcon id="copiedFields" fontSize="large" style={{ position: "fixed", top: 9, right: 372, opacity: 0 }} />;
        
    // Ссылку на внешнюю форму может выдать Админ шаблона
    // Есть глюк - если делать внешнюю ссылку из Админки, значения по умолчанию не попадают в предзаполненные,
    // нужно перейти на страницу Документ. Поэтому оставил икону "Ссылка на внешнюю форму" только на странице Документ - компонента Ext.
    const shareIcon = '';
    /*const shareIcon = props.isExtShareable && docId > 0 && docIdAdmin > -1 ? // только если форма уже в базе
        <Tooltip title="Ссылка на внешнюю форму" arrow>
        <IconButton
            color="primary"
            style={{ position: "fixed", top: -3, right: 265, zIndex: 1250 }}
            onClick={ () => getDocExtUrl( dispatch, docId, lang ) }
        >
            <ShareRounded className={ styles.xxUpdate } style={{ fontSize:"2rem" }} />
        </IconButton>
        </Tooltip> : '';*/
        
    // Обновить форму из базы может Админ шаблона
    const reloadIcon = docId > 0 && docIdAdmin > -1 ? // только если форма уже в базе
        <Tooltip title="Обновить форму из базы" arrow>
        <IconButton
            color="primary"
            style={{ position: "fixed", top: -4, right: 285, zIndex: 1250 }}
            onClick={ () => props.onLoad( docId, props.doc.docAdminList ) }
        >
            <RefreshRounded fontSize="large" className={ styles.xxUpdate } />
        </IconButton>
        </Tooltip> : '';

    // Сохранить форму может Админ шаблона
    const saveIcon = docId && docIdAdmin > -1 ?
        <Tooltip title="Сохранить форму" arrow>
        <IconButton
            color="primary"
            type="submit"
            form="adminForm"
            style={{ position: "fixed", top: -4, right: 210, zIndex: 1250 }}
        >
            <Badge color="error" variant="dot" badgeContent={ isChanged ? 1 : 0 }>
                <SaveOutlined fontSize="large" className={ isChanged ? styles.xxSaveActive : styles.xxSave } />
            </Badge>
        </IconButton>
        </Tooltip> : '';
        
    // вставить скопированные поля
    const buttonInsert = copyLength ?
        <Tooltip title="Вставить" arrow>
            <IconButton
                color="primary"
                style={{ position: "absolute", bottom: 85, left: -6 }}
                onClick={ () => { props.onInsert( null, copy ); props.onClearSelection(); } } // вставить в конец шаблона
            >
                <ArrowRightAltRounded fontSize="large" className={ styles.xxAdd } />
            </IconButton>
        </Tooltip> : '';
        
        
    return (
        <div className='AdminForm'>
        
        { copyIcon }
        { shareIcon }
        { reloadIcon }
        { saveIcon }

        <GroupEditor />
        <TemplateEditor />
        <ItemEditor />
        <ListEditor />
        <BankListsEditor />
        <ExtEditor />
        
        <Card className={ styles.xxGridActionPanel }>
        <CardContent className='Card'>
        
            { // Создавать, Изменять, Удалять группы может только Суперпользователь
              props.user.isSuper ?
                <>
                <Tooltip title="Новая группа" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: -10, left: -10 }}
                        onClick={ props.onGroupAdd }
                    >
                        <AddRounded fontSize="large" className={ styles.xxAdd } />
                    </IconButton>
                </Tooltip>
                
                <Tooltip title="Редактировать имя группы" arrow>
                <IconButton
                    color="primary"
                    style={{ position: "absolute", top: -5, right: 50 }}
                    onClick={ () => props.onGroupEdit( groupId, props.doc.groupNames ) }
                >
                    <EditRounded fontSize="default" className={ styles.xxAdd } />
                </IconButton>
                </Tooltip>
                
                <Tooltip title="Удалить группу" arrow>
                <IconButton
                    color="primary"
                    style={{ position: "absolute", top: -10, right: -10 }}
                    onClick={ () => props.onGroupDelete( groupId, props.doc.docNames ) }
                >
                    <ClearRounded fontSize="large" className={ styles.xxDelete } />
                </IconButton>
                </Tooltip>
                </> : ''
            }
            
            { // Новый шаблон можен создать Суперпользователь или Админ группы, ему будет присвоена роль Админа шаблона
              groupIdAdmin === -1 ? '' :
                <Tooltip title="Новый шаблон" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: 95, left: -10 }}
                        onClick={ () => props.onAdd( groupId ) }
                    >
                        <AddRounded fontSize="large" className={ styles.xxAdd } />
                    </IconButton>
                </Tooltip>
            }
            
            { !docId ? '' : <>
            
                { document.queryCommandSupported('copy') &&
                    <Tooltip title="Ссылка на шаблон" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: 155, right: -4 }}
                        onClick={ urlCopy }
                    >
                        <Send fontSize="default" className={ styles.xxAdd } />
                    </IconButton>
                    </Tooltip>
                }
                
                { // Копировать шаблон, Редактировать имя, код, группу, Удалить шаблон может Автор группы или Суперпользователь,
                  // который также является Админом шаблона
                  groupIdAdmin === -1 ||
                  docIdAdmin === -1 ? '' :
                    <>
                    <Tooltip title="Копировать шаблон" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: 95, right: 105 }}
                        onClick={ () => props.onCopy( docId, props.doc.docNames ) }
                    >
                        <PlaylistAddIcon fontSize="large" className={ styles.xxAdd } />
                    </IconButton>
                    </Tooltip>
                
                    <Tooltip title="Редактировать имя, код, группу шаблона" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: 100, right: 50 }}
                        onClick={ () => props.onEdit( docId, props.doc.docNames ) }
                    >
                        <EditRounded fontSize="default" className={ styles.xxAdd } />
                    </IconButton>
                    </Tooltip>
                    
                    <Tooltip title="Удалить шаблон" arrow>
                    <IconButton
                        color="primary"
                        style={{ position: "absolute", top: 95, right: -10 }}
                        onClick={ () => props.onDelete( docId, groupId ) }
                    >
                        <ClearRounded fontSize="large" className={ styles.xxDelete } />
                    </IconButton>
                    </Tooltip>
                    </>
                }
                </>
            }

            <ValidatorForm
                id="adminForm"
                onKeyPress={ props.onFormKeyPress }
                onSubmit={ props.onFormSubmit }
                onError={ props.onFormError }
            >
            <div className="AdminForm__table">

                { GroupSelect }
                <div style={{ padding: "21px" }} />
                { DocSelect }

                { !docId || docIdAdmin === -1 ? '' : <>
                <div className='AdminForm__row'>
                    <MButton variant="contained" color="primary" component="label">
                        Загрузить шаблон
                        <input
                          type="file"
                          accept=".rtf,.pdf,.docx,.csv,.xml,.zip,.7z"
                          style={{ display: "none" }}
                          onChange={ props.onLoadTemplate }
                        />
                    </MButton>
                    <div className='padding' />
                    <Components.Button onClick={ () => props.onSaveTemplate( docId ) } label="Выгрузить шаблон" />
                    <div className='padding' />
                    <Components.Button onClick={ e => props.onSaveSampleTemplate( e ) } label="&nbsp; &nbsp; &nbsp; Инструкция &nbsp; &nbsp; &nbsp;" />
                </div>
                
                {/* карточки для редактирования полей формы */}
                <div className='padding'>
                    { props.DynForm ?
                        <Movable
                            items={ props.DynForm }
                            template={ props.docTemplate }
                            onOrderChange={ props.onOrderChange }
                        />
                        : props.spin.isLoading ? '' : 'За доступом обратитесь к владельцу шаблона.'
                    }
                </div>
                
                { buttonInsert }
                
                <div className='padding' style={{ textAlign: 'center' }}>
                    <Tooltip title="Добавить поле" arrow>
                        <MButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={ props.onAppendField }
                        >
                            <AddRounded />
                        </MButton>
                        {/* для Tooltip нужно React.forwardRef, не получилось, поэтому использую MButton
                        <Components.Button onClick={ props.onAppendField } label={
                            <AddRounded />
                        } />*/}
                    </Tooltip>
                </div>
                </>
                }

            </div>
            </ValidatorForm>
            
        </CardContent>
        </Card>
        </div>
    )
}

export default AdminForm
