import { connect } from 'react-redux'
import React, { Component } from 'react'
import { default as VBankListsEditor } from '../components/AdminParts/BankListsEditor'
import {
    BANK_DET_LIST_EDITOR,
    FORM_TEMPLATE
} from '../redux/constants'
import {
    close
} from '../redux/actions/bankListsEditor'
import {
    setPropValue
   ,setKeyPropValue
} from '../redux/actions/utils'

class BankListsEditor extends Component {
 
    render() {
        return (
            <VBankListsEditor
                { ...this.props }
            />
        )
    }
}


const mapStateToProps = ( { bankListsEditor, doc } ) => {
    return {
        ...bankListsEditor
       ,yaOrgs: doc.yaOrgs
    };
}


const mapDispatchToProps = dispatch => ({
    onChangeBankNameDefaultValue: ( e ) => {
        dispatch( setPropValue( BANK_DET_LIST_EDITOR, 'bankNameDefaultValue', e.target.value ) );
     }
    ,onChangeBankNameWhiteList: ( e ) => {
        dispatch( setPropValue( BANK_DET_LIST_EDITOR, 'bankNameWhiteList', e.target.value ) );
    }
   ,onOk: ( props ) => {
        const bankDetails =
            {
                item: props.item,
                bankNameWhiteList: props.bankNameWhiteList,
                bankNameDefaultValue: props.bankNameDefaultValue
            };

        let listBankDetails = props.listBankDetails;

        listBankDetails = !listBankDetails ? undefined : listBankDetails.filter( i => i.item !== props.item);
        listBankDetails = !listBankDetails ? [bankDetails] : [...listBankDetails,bankDetails];

        dispatch( setKeyPropValue( FORM_TEMPLATE, props.itemKey, 'listBankDetails', listBankDetails ) );
    }
   , onCancel: () => dispatch( close() )
})

export default connect( mapStateToProps, mapDispatchToProps )( BankListsEditor )
