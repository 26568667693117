import React from 'react'
import {withStyles} from '@material-ui/core/styles';
import styles from "../AdminForm/Form.module.css"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ValidatorForm } from 'react-material-ui-form-validator';
import RoleList from './RoleList';
import ExcelActions from './ExcelActions';
import {
    getAdminDocSelect
   ,getAdminGroupSelect
} from '../../redux/actions/admin'

const classes = {
};

const RoleForm = ( props ) => {
    
    const GroupSelect = getAdminGroupSelect( props );
    const DocSelect = getAdminDocSelect( props );
    const docId = props.doc.docId;
    const groupId = props.doc.groupId;

    // список лиц и подразделений из Staff
    // Авторы групп
    const StaffSelectGroupAuthor = props.doc.docParts.InputWithSelect.build(
        props
       ,props.role
       ,{
            label: "Авторы с правом на создание шаблонов в группе:"
           ,name: "staffGroupAuthor"
           ,list: props.role.staffGroupAuthorList
           ,onNameValueChange: f=>f
           ,onInputChange: value => props.onStaffInput( "staffGroupAuthorList", value )
           ,onSelectChange: value => props.onStaffSelectChange( groupId, "groupAuthorList", value, "GROUP", "ADMIN", props.role.tmpIdSequence )
       }
    );
    // Админы шаблона
    const StaffSelectTemplateAdmin = props.doc.docParts.InputWithSelect.build(
        props
       ,props.role
       ,{
            label: "Админы шаблона:"
           ,name: "staffTemplateAdmin"
           ,list: props.role.staffTemplateAdminList
           ,onNameValueChange: f=>f
           ,onInputChange: value => props.onStaffInput( "staffTemplateAdminList", value )
           ,onSelectChange: value => props.onStaffSelectChange( docId, "templateAdminList", value, "TEMPLATE", "ADMIN", props.role.tmpIdSequence )
       }
    );
    // Пользователи шаблона
    const StaffSelectTemplateUser = props.doc.docParts.InputWithSelect.build(
        props
       ,props.role
       ,{
            label: "Пользователи шаблона:"
           ,name: "staffTemplateUser"
           ,list: props.role.staffTemplateUserList
           ,onNameValueChange: f=>f
           ,onInputChange: value => props.onStaffInput( "staffTemplateUserList", value )
           ,onSelectChange: value => props.onStaffSelectChange( docId, "templateUserList", value, "TEMPLATE", "USER", props.role.tmpIdSequence )
       }
    );
    
    
    return (
        <div className='AdminForm'>
        
        <ValidatorForm
            id="roleForm"
            onKeyPress={ props.onFormKeyPress }
            onSubmit={ props.onFormSubmit }
        >
        
        <Card className={ styles.xxGridActionPanel }>
        <CardContent className='Card'>
            
            <div className="AdminForm__table">

                { GroupSelect }
                
                { // Авторов групп назначает Суперпользователь
                  groupId > 0 && props.user.isSuper ?
                    <>
                    
                    {  StaffSelectGroupAuthor }
                    
                    { !props.role.groupAuthorList || !groupId  || !props.role.groupAuthorList[ groupId ] ? '' :
                        <RoleList
                            list = { props.role.groupAuthorList[ groupId ] }
                            onDelete = { ( roleId, itemType, itemId ) => props.onDelete( groupId, "groupAuthorList", roleId, itemType, itemId ) }
                        />
                    }

                    </> : ''
                }

            </div>

        </CardContent>
        </Card>
        <p/>
        
        { // Админов и Пользователей шаблона назначает Админ шаблона или Суперпользователь
          props.doc.docAdminList.length ?
            <Card className={ styles.xxGridActionPanel }>
            <CardContent className='Card'>

                <div className="AdminForm__table">
                    { DocSelect }

                    { // docId может прийти из адресной строки, поэтому проверяем
                      docId <= 0 || props.doc.docAdminList.findIndex( i => i.id === docId ) === -1 ? '' :
                    
                        <>

                        { StaffSelectTemplateAdmin }
                        
                        { !props.role.templateAdminList || !docId  || !props.role.templateAdminList[ docId ] ? '' :
                            <RoleList
                                list = { props.role.templateAdminList[ docId ] }
                                onDelete = { ( roleId, itemType, itemId ) => props.onDelete( docId, "templateAdminList", roleId, itemType, itemId ) }
                            />
                        }

                        { StaffSelectTemplateUser }
                        
                        { !props.role.templateUserList || !docId  || !props.role.templateUserList[ docId ] ? '' :
                            <RoleList
                                list = { props.role.templateUserList[ docId ] }
                                onDelete = { ( roleId, itemType, itemId ) => props.onDelete( docId, "templateUserList", roleId, itemType, itemId ) }
                            />
                        }
                        
                        </>
                    }
                    
                </div>

            </CardContent>
            </Card> : ''
        }
        <p/>
        
        
        { // CDOC-288 Разделение доступа к персональным данным
          props.user.isSuper || props.user.isHrSuper ?
            <Card className={ styles.xxGridActionPanel }>
            <CardContent className='Card'>
                <center>Доступ к функциональным компонентам</center>
                <div className="AdminForm__table">
                    <ExcelActions
                        onRoleMatrixDownload = { props.onRoleMatrixDownload }
                        onRoleMatrixUpload = { props.onRoleMatrixUpload }
                        onRoleTreeDownload = { props.onRoleTreeDownload }
                        onRoleTreeUpload = { props.onRoleTreeUpload }
                    />
                </div>

            </CardContent>
            </Card> : ''
        }
        
        
        </ValidatorForm>
        </div>
    )
}

export default withStyles(classes)(RoleForm);
