import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Grow from '@material-ui/core/Grow';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const Modal = ( props ) => {
 
    if( props.msg === null ) return ''; // чтобы закрывался резче
    return (
        <Dialog
            open={ true }
            TransitionComponent={ Transition }
            onBackdropClick={ props.onCancel }
            onEscapeKeyDown={ props.onCancel }
        >
            {/*<DialogTitle>{ props.title ? props.title : "Error" }</DialogTitle>*/}
            <DialogContent>
                <DialogContentText>{ props.msg }</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={ props.onOk === undefined ? props.onCancel : () => { props.onCancel(); props.onOk(); } }
                    color="secondary"
                    variant="outlined"
                    size="small"
                    autoFocus
                > Ок </Button>
                { props.onOk === undefined ? '' :
                    <Button onClick={ props.onCancel } color="secondary" variant="outlined" size="small" > Отмена </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default Modal;
