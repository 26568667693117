import React from 'react'
import Button from '../AdminParts/Button';
import MButton from "@material-ui/core/Button";

const ExcelActions = ( props ) => {
    return (
        <div>
        <div className = 'AdminForm__row'>
            <Button onClick = { props.onRoleMatrixDownload } label = "Скачать матрицу ролей" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MButton variant = "contained" color = "primary" component = "label">
                Загрузить матрицу ролей
                <input
                  type = "file"
                  accept = ".xls,.xlsx"
                  style = {{ display: "none" }}
                  onChange = { props.onRoleMatrixUpload }
                />
            </MButton>
        </div>
        <p/>
        <div className = 'AdminForm__row'>
            <Button onClick = { props.onRoleTreeDownload } label = "Скачать дерево ролей" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MButton variant = "contained" color = "primary" component = "label">
                Загрузить дерево ролей
                <input
                  type = "file"
                  accept = ".xls,.xlsx"
                  style = {{ display: "none" }}
                  onChange = { props.onRoleTreeUpload }
                />
            </MButton>
        </div>
        </div>
    );
}

export default ExcelActions;
