import axios from 'axios';
import * as moment from "moment";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { default as VHistoryForm } from '../components/HistoryForm/HistoryForm';
import {
// properties
    APP_URL
// reducer names
   ,EDIT_DIALOG
   ,HISTORY
} from '../redux/constants';
import {
    docChange
   ,groupChange
   ,load
} from '../redux/actions/admin';
import {
    get_common
   ,setKeyPropValue
   ,setPropValue
   ,setProps
} from '../redux/actions/utils';
import {
    saveCommentDB
} from '../redux/actions/history';
import {
    requestFile
} from '../redux/actions/doc';


let needUpdate = true;

class HistoryForm extends Component {

    // вызывается при смене Шаблона,
    // а при переходе на страницу История - не вызывается
    componentDidUpdate( prevProps ) {
        // загрузить шаблон из базы, если его нет
        load( this.props, prevProps );
        
        if( this.props.doc.docId !== prevProps.doc.docId ) this.props.onUpdateHistory();
    }
    
    // вызывается при переходе на страницу История,
    // но если onUpdateHistory() вызовет ошибку,
    // появится диалог, который вызовет unmount, затем mount, и onUpdateHistory(),
    // это приводит к бесконечном циклу,
    // с которым борется needUpdate, пока ничего лучше не придумал
    componentDidMount() {
        if( needUpdate ) this.props.onUpdateHistory();
    }
    
    componentWillUnmount() {
        if( this.props.modal.msg ) needUpdate = false;
    }

    render() {
        return (
            <VHistoryForm
                { ...this.props }
            />
        )
    }
}


const mapStateToProps = ( { doc, history, role, spin, user, modal } ) => {
    return {
        doc
       ,history
       ,role
       ,spin
       ,user
       ,modal
    };
}

let cancelRequest;

const mapDispatchToProps = dispatch => ({
    dispatch // передаю dispatch в props, для load
    // prevent firing button on Enter
   ,onFormKeyPress: ( e ) => {
        // allow Enter in textarea
        var txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
        if( txtArea || (e.keyCode || e.which || e.charCode || 0) !== 13 ) {
            return true;
        } else {
            e.preventDefault();
        }
        return false;
    }
    // форму убрать нельзя, Material UI компоненты привязываются к форме
   ,onFormSubmit: ( e ) => {
        e.preventDefault();
    }
   ,onDocChange: ( e ) => docChange( dispatch, e )
   ,onGroupChange: ( e, oldGroupId ) => groupChange( dispatch, e, oldGroupId )
   ,onChange: ( e ) => {
    
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked :
                      target.type === 'file' && target.files[0] ? target.files[0].name :
                      target.value;
        dispatch( setPropValue( HISTORY, name, value ) );
    }
   ,onNameValueChange: ( name, value ) => {
        dispatch( setPropValue( HISTORY, name, value ) );
    }
   ,onDownload: ( template_id, version_id, fileName, format, source, date_time, tzOffset ) => {
    
        // template_id нужен, чтобы проверить права на шаблон,
        // version_id - id записи в базе,
        // format - json / rtf / pdf / docx,
        // source - актуальный или из истории,
        // date_time - для выбора partition в таблице истории, hist_end_date
        // tzOffset - смещение timezone в минутах, -180 для Москвы
        
        if( !Number.isInteger( template_id ) ) return;
        if( !Number.isInteger( version_id ) ) return;
        const formData = new FormData();
        formData.append('template_id', template_id);
        formData.append('version_id', version_id);
        formData.append('source', source);
        formData.append('format', format);
        formData.append('date_time', date_time);
        formData.append('tzOffset', tzOffset);

        requestFile( APP_URL + "/api/history/download", fileName, format, dispatch, formData );
    }
   ,onUpdateHistory: () => {

        const props = dispatch( ( dispatch, getState ) => getState() );
        const id = props.doc.docId;
        const history = props.history;
        const dateFrom = history.dateFrom;
        const dateTo = history.dateTo
        const limit = history.limit === '' ? '' : Number.parseInt( history.limit, 10 );
        const tzOffset = history.tzOffset;

        if( !id ) return;
        if( limit !== '' && ( limit <= 0 || !Number.isInteger( limit ) ) ) return;
        if( !moment( dateFrom, "DD.MM.YYYY" ).isValid() ) return;
        if( !moment( dateTo,   "DD.MM.YYYY" ).isValid() ) return;
        if( props.doc.docAdminList.findIndex( i => i.id === id ) === -1 ) return;
   
        if( cancelRequest ) {
            cancelRequest.cancel();
        }
        cancelRequest = axios.CancelToken.source();

        // TODO если нужны даты, добавить tzOffset
        get_common(
            APP_URL + '/api/template'
           ,dispatch
           ,'Ошибка при получении информации о шаблоне: '
           ,data => {
                dispatch( setKeyPropValue( HISTORY, id, 'present', data[0] ) );
            }
           ,f=>f
           ,null
           ,{ cancelToken: cancelRequest.token
             ,params: { id }, }
        );
        
        get_common(
            APP_URL + '/api/history'
           ,dispatch
           ,'Ошибка при получении истории изменения шаблона: '
           ,data => {
                dispatch( setKeyPropValue( HISTORY, id, 'past', data ) );
            }
           ,f=>f
           ,null
           ,{ cancelToken: cancelRequest.token
             ,params: { id, dateFrom, dateTo, limit, tzOffset }, }
        );
    }
   ,onCommentEdit: ( hist_id, template_id, hist_end_date, tzOffset, value ) => {
        dispatch(
            setProps( EDIT_DIALOG, {
                open: true
               ,label: "Комментарий"
               ,value
               ,onOk: ( result ) => saveCommentDB( dispatch, hist_id, template_id, hist_end_date, tzOffset, result )
            } )
        );
    }
})

export default connect( mapStateToProps, mapDispatchToProps )( HistoryForm )
