import React from 'react'
import Button from '../AdminParts/Button';
import MButton from "@material-ui/core/Button";

const UploadsActions = ({ props, uploads } ) => {
    const docId = props.doc.docId;
    return (
        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                <div style={{marginLeft: "-105px"}}>
                    <Button
                        name="downloadMassDocGenerationTemplate"
                        onClick={() => props.onLoadMassDocGenerationTemplate(docId)}
                        label="Скачать шаблон"
                    />
                </div>
                <div style={{marginLeft: "-300px"}}>
                    <MButton
                        variant="contained" color="primary" component="label"
                    >
                        Загрузить файл с данными
                        <input
                            type="file"
                            accept=".xls"
                            onChange={(e) => {
                                props.onLoad( e, docId );
                                props.onUpdateUploads();
                            }}
                            style={{display: 'none'}}
                            name={props.name}
                        />
                    </MButton>
                </div>
            </div>
            <div style={{marginLeft: "7px"}}></div>
        </div>
    );
}

export default UploadsActions;
