import { connect } from 'react-redux'
import React, { Component } from 'react'
import { default as VTemplateEditor } from '../components/AdminForm/TemplateEditor'
import {
    TEMPLATE_EDITOR
} from '../redux/constants'
import {
    close
   ,addTemplate
   ,copyTemplate
   ,editTemplate
   ,addTemplateDB
   ,copyTemplateDB
   ,editTemplateDB
} from '../redux/actions/templateEditor'
import {
    setPropValue
} from '../redux/actions/utils'

class TemplateEditor extends Component {
 
    render() {
        return (
            <VTemplateEditor
                { ...this.props }
            />
        )
    }
}


const mapStateToProps = ( { doc } ) => {
    return {
        ...doc.templateEditor
       ,groupNames: doc.groupNames
       ,groupAdminList: doc.groupAdminList
    };
}


const mapDispatchToProps = dispatch => ({
    onChange: ( e ) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked :
                      target.value;
        dispatch( setPropValue( TEMPLATE_EDITOR, name, value ) );
    }
   ,onOk: ( goal, docId, docName, code, groupId ) => {
        const name = docName || "Новый";
        if( goal === 'add' ) { dispatch( addTemplate( name, code, groupId ) ); addTemplateDB( dispatch, name, code, groupId ); }
        if( goal === 'copy' ) { dispatch( copyTemplate( docId, name, code, groupId ) ); copyTemplateDB( dispatch, docId, name, code, groupId ); }
        if( goal === 'edit' ) { dispatch( editTemplate( docId, name, code, groupId ) ); editTemplateDB( dispatch, docId, name, code, groupId ); }
    }
   ,onCancel: () => dispatch( close() )
})

export default connect( mapStateToProps, mapDispatchToProps )( TemplateEditor )


